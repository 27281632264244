<div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="modal-title">Confirm Wallet Address</h4>
    <div class="modal-body">
      <p style="text-align: center">{{description}}</p>
      <div class="field" style="text-align: center">{{walletAddress}}</div>
    </div>
    <div class="d-flex justify-content-around align-items-center footer">
      <button type="button" class="highlighted-btn" (click)="proceed()">Proceed</button>
      <button type="button" class="highlighted-btn" (click)="cancel()">Cancel</button>
    </div>
  </div>