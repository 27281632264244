<section class="tokenomics-section">
    <div class="tokenomics-items d-flex flex-column justify-content-center align-items-center blue-waves-background-repeat">
        <h1 class="tokenomics-title title">
            Tokenomics
        </h1>

        <div class="body-text">
            <h5 class="d-flex justify-content-between"><span class="tokenomics-label">Total Supply:</span> <span class="tokenomics-value">1,000,000,000</span></h5>
            <h5 class="d-flex justify-content-between"><span class="tokenomics-label">Decimal:</span> <span class="tokenomics-value">18</span></h5>
            <h5 class="d-flex justify-content-between"><span class="tokenomics-label">Max transaction amount:</span> <span class="tokenomics-value">1,000,000</span></h5>

        </div>

        <div class="chart-container">
            <apx-chart [legend]="legend" [series]="series" [chart]="chart" [labels]="labels" [fill]="fill"
                [stroke]="stroke"></apx-chart>
        </div>
    </div>
</section>