<section class="economy-section blue-waves-background">
    <div class="economy-items">
        <h1 class="title">Economy</h1>
        <div class="diagram">
            <img class="economy-main-image" src="/assets/images/background-images/workflow.png">
            <div class="info">
                <h5 class="info-text">Affiliate Program: DCT Network</h5>
                <div class="info-line"></div>
            </div>

            <div class="info">
                <div class="info-line"></div>
                <h5 class="info-text">E-Commerce</h5>
            </div>

            <div class="info">
                <h5 class="info-text">Real Estate</h5>
                <div class="info-line"></div>
            </div>
        </div>
    </div>
</section>
