import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { ToastrService } from 'ngx-toastr';

import { FormBuilder, Validators } from '@angular/forms';
import { BscApiService } from 'src/app/data/api/bsc/bsc-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DctLoginComponent } from '../asset-reward/modals/dct-login/dct-login.component';

type TTimeObj = {
  status: boolean;
  rate: string;
  days: number;
}

@Component({
  selector: 'app-asset-revest',
  templateUrl: './asset-revest.component.html',
  styleUrls: ['../asset-reward/asset-reward.component.scss'],
})
export class AssetRevestComponent implements OnInit {
  isDetails = false;
  durationWalletRes: any[] = [];
  wallet: string = '';
  balance: string | null = null;
  selectedTime: TTimeObj | null = null;
  loading: boolean = false;
  enableAmount: boolean = false;
  minimumAmount: number | null = null;
  insufficientBalance: boolean = false;
  username: string = '';
  previous_txn_no : number = 0;
  total_amount : number = 0;

  rewardForm = this.fb.group({
    time: ['', Validators.required],
    // amount: ['', [Validators.required, Validators.min(1)]],
    password: ['', Validators.required],
    consent: [false, Validators.requiredTrue],
  });

  constructor(
    private modalService: NgbModal,
    private janexApi: JanexApiService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private bscApi: BscApiService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.previous_txn_no = this.router.getCurrentNavigation()?.extras?.state?.['data'].previous_txn_no;
    this.total_amount = this.router.getCurrentNavigation()?.extras?.state?.['data'].total_amount;
  }

  onMouseMove(event: any) {
    const mouse = {
      x: event.clientX,
      y: event.clientY,
    };

    let image = document.getElementsByClassName(
      'image-animate'
    ) as HTMLCollectionOf<HTMLElement>;

    if (image.length > 0) {
      let imageRect = image[0].getBoundingClientRect();

      const imageCenterPosition = {
        x: imageRect.left + imageRect.width / 2,
        y: imageRect.top + imageRect.height / 2,
      };

      const distanceX = mouse.x - imageCenterPosition.x;
      const distanceY = mouse.y - imageCenterPosition.y;

      const angleInRadians = Math.atan2(distanceX, -distanceY);
      const angleInDegrees = angleInRadians * (180 / Math.PI);

      let blockchainPolyhedron = document.getElementsByClassName(
        'image-animate'
      ) as HTMLCollectionOf<HTMLElement>;
      blockchainPolyhedron[0].style.transform =
        'rotate(' + angleInDegrees + 'deg)';
    }
  }

  private async getWalletBalance() {
    try {
      const balance = await this.bscApi.getBalance(this.wallet);
      this.balance = balance;
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  }

 updateAmount(percentage: number): void {
    if (this.balance !== null) {
      const calculatedAmount = (percentage / 100) * parseFloat(this.balance);
      // this.rewardForm.patchValue({ amount: calculatedAmount });
      // this.rewardForm.patchValue({ amount: calculatedAmount.toFixed(2).toString() });
    }
  }

  async ngOnInit() {
    const auth_token = this.janexApi.getCookieValue('auth_token');
 
    if(!this.total_amount ){
      this.router.navigate(['/asset-reward/report']);
    }

    if (!auth_token) {
      this.modalService.open(DctLoginComponent);
    } else {
      try {

        const response = await this.janexApi.durationWallet();

        if (response.success) {
          this.isDetails = true;
          this.durationWalletRes = response.data.duration;
          this.wallet = response.data.wallet;
          this.minimumAmount = Number(response.data.minimumAmount);
          this.username = response.data.username;

          //  const test = await this.getWalletBalance()
          await this.getWalletBalance();
          const balanceAsNumber = parseFloat(this.balance || '0');

          if(this.minimumAmount > Number(this.balance)) {
            this.insufficientBalance = true;
          }

          this.rewardForm
            .get('amount')
            ?.setValidators([
              Validators.required,
              Validators.min(this.minimumAmount),
              Validators.max(balanceAsNumber),
            ]);
          this.rewardForm.get('amount')?.updateValueAndValidity();
        } else {
          this.toastr.error('Something went wrong at durationWallet()');
        }
      } catch (error: any) {
        if (error.status === 401) {
          this.modalService.open(DctLoginComponent);
        } else {
          const errorMessage = this.translate.instant(
            error?.error?.data?.error!.toString()
          );
          this.toastr.error(errorMessage);
          // this.toastr.error(error?.error?.data?.error);
          // window.location.href= "/";
        }
      }
    }

    this.rewardForm.get('time')!.valueChanges.subscribe((selectedValue) => {
      const durationObj = this.durationWalletRes.find(
        (duration) => duration.id === selectedValue
      );

      this.selectedTime = {
        status: true,
        rate: durationObj.reward_percentage,
        days: durationObj.days,
      };
    });
  }

  onSubmit() {
    this.vest();
  }

  private async vest() {
    this.loading = true;

    try {
      const formData = new FormData();

      formData.append('time', this.rewardForm.value.time!);
      // formData.append('amount', this.total_amount!.toString());
      formData.append('password', this.rewardForm.value.password!);
      formData.append('consent', this.rewardForm.value.consent!.toString());
      formData.append('previous_txn_no', this.previous_txn_no!.toString());

      let response = await this.janexApi.revest(formData);

      if (response.success) {
        this.toastr.success(
          `You have successfully locked ${this.total_amount} JNX for a reward!`
        );
        this.router.navigate(['/asset-reward/report']);
        this.rewardForm.reset({});
      } else {
        const errorKey = response.error;
        this.toastr.error(this.translate.instant(errorKey));
      }
      this.loading = false;

    } catch (err: any) {
      const message = err.error.message;

      if (err.status === 401) {
        this.modalService.open(DctLoginComponent);
      } else if (typeof message == 'object') {
        for (const [key, value] of Object.entries(message)) {
        const errorMessage = this.translate.instant(value!.toString());
          this.toastr.error(errorMessage);
        }
      } else {
        const errorMessage = this.translate.instant(message!.toString());
        this.toastr.error(errorMessage);
      }
      this.loading = false;
    }
  }

  handleAmountClick() {
    this.enableAmount = true;
  }

  setButtonCSS() {
    if(this.loading || !this.rewardForm.valid) {
      return 'disable-button';
    } else {
      return 'enable-button';
    }
  }
}
