import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeModule } from './pages/home/home.module';
import { ConfirmationModalComponent } from './pages/purchase/modals/confirmation-modal/confirmation-modal.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { DataModule } from './data/data.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AwaitingPaymentModalComponent } from './pages/purchase/modals/awaiting-payment-modal/awaiting-payment-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EncryptionModule } from './modules/encryption/encryption.module';
import { BtcModalComponent } from './pages/purchase/modals/btc-modal/btc-modal.component';
import { ConnectButtonModalComponent } from './directives/connect-wallet-button/connect-button-modal/connect-button-modal.component';
import { ConnectWalletButtonDirective } from './directives/connect-wallet-button/connect-wallet-button.directive';
import { EmailModalComponent } from './pages/purchase/modals/email-modal/email-modal.component';
import { EnterAddressModalComponent } from './pages/purchase/modals/enter-address-modal/enter-address-modal.component';
import { MiddleEllipsisDirective } from './directives/middle-ellipsis/middle-ellipsis.directive';
import { ConfirmAddressModalComponent } from './pages/purchase/modals/confirm-address-modal/confirm-address-modal.component';
import { JanexWalletPickerComponent } from './pages/purchase/components/janex-wallet-picker/janex-wallet-picker.component';
import { ExternalReceiptModalComponent } from './pages/purchase/modals/external-receipt-modal/external-receipt-modal.component';
import { DctSignInComponent } from './pages/purchase/modals/dct-sign-in/dct-sign-in.component';
import { AssetRewardComponent } from './pages/asset-reward/asset-reward.component';
import { DctLoginComponent } from './pages/asset-reward/modals/dct-login/dct-login.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import { WalletBalanceComponent } from './pages/asset-reward/components/wallet-balance/wallet-balance.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import { NgIf } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { AssetRevestComponent } from './pages/asset-revest/asset-revest.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    ConfirmationModalComponent,
    PurchaseComponent,
    AwaitingPaymentModalComponent,
    BtcModalComponent,
    ConnectButtonModalComponent,
    ConnectWalletButtonDirective,
    EmailModalComponent,
    EnterAddressModalComponent,
    MiddleEllipsisDirective,
    ConfirmAddressModalComponent,
    JanexWalletPickerComponent,
    ExternalReceiptModalComponent,
    DctSignInComponent,
    AssetRewardComponent,
    DctLoginComponent,
    WalletBalanceComponent,
    AssetRevestComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    HomeModule,
    DataModule,
    FormsModule,
    QRCodeModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    EncryptionModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    NgIf,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService) {
    // Set French as the default language
    translate.setDefaultLang('en');

    // Load translation files
    translate.use('en'); // Load English translations
    // translate.use('ro'); // Load French translations
  }
 }
