import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.timer();
  }

  timer() {
    let countDownDate = new Date("Nov 14, 2022").getTime();

    // Run myfunc every second
    let myfunc = setInterval(function() {

      let now = new Date().getTime();
      let timeleft = countDownDate - now;

      // Calculating the days, hours, minutes and seconds left
      let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

      (document.getElementById("days") as HTMLFormElement).innerText = days<10?`0${days}`:`${days}`;
      (document.getElementById("hours") as HTMLFormElement).innerText = hours<10?`0${hours}`:`${hours}`;
      (document.getElementById("minutes") as HTMLFormElement).innerText = minutes<10?`0${minutes}`:`${minutes}`;
      (document.getElementById("seconds") as HTMLFormElement).innerText = seconds<10?`0${seconds}`:`${seconds}`;

    }, 1000);
  }

}
