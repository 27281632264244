import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncryptionService } from 'src/app/modules/encryption/services/encryption.service';
import { environment } from 'src/environments/environment';
import { BscResponse } from './bsc-models';
import { janexAbi } from 'src/app/web3-services/smart-contracts/janex/janex-model';
import Web3 from "web3";

@Injectable()
export class BscApiService {

  constructor(private http : HttpClient, private encryptionService : EncryptionService) { }

  getJanexTransactionsForWallet<T>(walletAddress : string) : Observable<BscResponse<T>> {
    let reqUrl = `${environment.bscApiBaseUrl}`
    let params = new HttpParams()
    .append('module', 'account')
    .append('action', 'tokentx')
    .append('contractadress', environment.janexContractAddress)
    .append('address', walletAddress)
    .append('page', 1)
    .append('offset', 5)
    .append('startblock', 0)
    .append('endblock', 999999999)
    .append('sort', 'asc')

    return this.http.get<BscResponse<T>>(reqUrl, {params: this.appendApiKey(params)})
  }

  getTransactionStatus<T>(txHash : string) : Observable<BscResponse<T>> {
    let reqUrl = `${environment.bscApiBaseUrl}`
    let params = new HttpParams()
    .append('module', 'transaction')
    .append('action', 'gettxreceiptstatus')
    .append('txhash', txHash)
  
    return this.http.get<BscResponse<T>>(reqUrl, {params: this.appendApiKey(params)});
  }

  private appendApiKey(params : HttpParams) : HttpParams {
    return params.append('apikey', this.encryptionService.aes.decrypt(environment.encryptedBscApiKey))
  }

  async getBalance(wallet: string) {
    const janexContractAddress = environment.janexContractAddress;

    const jnxWallet = wallet;
    const provider = environment.bscProviderUrl;
    const web3 = new Web3(new Web3.providers.HttpProvider(provider));
    // let contract = new web3.eth.Contract(janexAbi, janexContractAddress);
    let contract = new web3.eth.Contract(janexAbi, web3.utils.toChecksumAddress(janexContractAddress));
    const balance = await contract.methods.balanceOf(jnxWallet).call();
    // return Promise.resolve(balance / 1000000000000000000);
    return Promise.resolve(
      (Math.floor((balance / 1e18) * 100) / 100).toFixed(2)
    );

  };

}
