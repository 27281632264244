import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppCrypto } from 'src/app/modules/cryptocurrencies/app-cryptos';
import { Bnb } from 'src/app/web3-services/smart-contracts/bnb/bnb';
import { BusdContractService } from 'src/app/web3-services/smart-contracts/busd/busd-contract.service';
import { UsdtContractService } from 'src/app/web3-services/smart-contracts/usdt/usdt-contract.service';
import { PeriodicBalanceChecker } from './periodic-balance-checker';

@Injectable({
  providedIn: 'root'
})
export class PaymentListenerService {
  private bnb = new Bnb();
  constructor(private usdtContract : UsdtContractService, private busdContract : BusdContractService) {
  }

  async listenToPayment(currency : AppCrypto, amountToBePaid : number, targetWallet : string) : Promise<Observable<PaymentInfo>> {
    let fun : () => Promise<number>;
    switch (currency) {
      case 'BNB':
        fun = () => {return this.bnb.getBnbBalance(targetWallet)};
        break;
      case 'BUSD':
        fun = () => {return this.busdContract.balanceOfWallet(targetWallet)}
        break;
      case 'USDT':
        fun = () => {return this.usdtContract.balanceOfWallet(targetWallet)};
        break;
        default:
          throw Error('')
    }
    let checker= new PeriodicBalanceChecker(4000, () => {return fun()})
    let retObservable = new BehaviorSubject<PaymentInfo>({type: 'no-change', paidAmount: 0});
    let previousBalance = 0;
    let subscription = checker.asObservable().subscribe({next: (balance) => {
      if(balance < 1) {
        retObservable.next({type: 'no-change', paidAmount: 0})
      } else if(balance < amountToBePaid && balance > previousBalance) {
        retObservable.next({type: 'partial', paidAmount: balance - previousBalance})
        previousBalance = balance;
      } else if(balance >= amountToBePaid) {
        retObservable.next({type: 'full', paidAmount: amountToBePaid})
        subscription.unsubscribe()
      }
    }})

    return retObservable.asObservable();
  }
}


export interface PaymentInfo {
  type : 'no-change' | 'partial' | 'full',
  paidAmount: number
}