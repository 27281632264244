import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppWeb3Service } from './web3-services/app-web3.service';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DctLoginComponent } from './pages/asset-reward/modals/dct-login/dct-login.component';
import { Router } from '@angular/router'; // Import the Angular Router

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  walletConnected = false;
  walletAddress: string = '';
  auth_token: string | null;; // Assuming this is where you store the auth_token
  buttonText: string;

  constructor(private web3: AppWeb3Service, private janexApi: JanexApiService, 
    private modalService: NgbModal, private router: Router 
    ) {
    this.auth_token = this.janexApi.getCookieValue('auth_token')!;
    this.buttonText = this.auth_token ? 'Log Out' : 'Log In';
    
  }

  onMouseMove(event: any) {
    const mouse = {
      x: event.clientX,
      y: event.clientY,
    };

    let image = document.getElementsByClassName(
      'image-animate'
    ) as HTMLCollectionOf<HTMLElement>;

    if (image.length > 0) {
      let imageRect = image[0].getBoundingClientRect();

      const imageCenterPosition = {
        x: imageRect.left + imageRect.width / 2,
        y: imageRect.top + imageRect.height / 2,
      };

      const distanceX = mouse.x - imageCenterPosition.x;
      const distanceY = mouse.y - imageCenterPosition.y;

      const angleInRadians = Math.atan2(distanceX, -distanceY);
      const angleInDegrees = angleInRadians * (180 / Math.PI);

      let blockchainPolyhedron = document.getElementsByClassName(
        'image-animate'
      ) as HTMLCollectionOf<HTMLElement>;
      blockchainPolyhedron[0].style.transform =
        'rotate(' + angleInDegrees + 'deg)';
    }
  }

  async ngOnInit() {
    this.web3.initialize();
  }

  handleAuthentication(): void {
    if (this.auth_token) {
      this.logOut();
    } else {
      this.logIn();
    }
  }

  logOut(): void {
    this.janexApi.signOut()

    document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    this.buttonText = 'Log In';

    window.location.href = "/"
  }

  logIn(): void {
    this.modalService.open(DctLoginComponent);
    
  }

  toggleMobileMenu(): void {
    const hamburgers = document.querySelectorAll('.hamburger');
    const headerMobile = document.querySelector('.header-mobile');

    hamburgers.forEach((hamburger: Element) => {
      hamburger.classList.toggle('active');
    });

    if (headerMobile) {
      headerMobile.classList.toggle('visible');
    }
  }
}
