import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import * as crypto from 'crypto';
import { Blowfish } from 'egoroof-blowfish';


@Injectable()
export class EncryptionService {
  public aes : Cipher = new AESCipher(environment.encryptionKey);

  public blowfish : Cipher = new BlowFishCipher(environment.encryptionKey);

  public hmac : Cipher = new HmacCipher(environment.hmacEncryptionKey);

  constructor() { }
}


interface Cipher {
  encrypt(str : string) : string;

  decrypt(str : string) : string;
}

class AESCipher implements Cipher {
  constructor(private key : string){}

  public encrypt(str: string) {
      var ctr = CryptoJS.AES.encrypt(str, this.key).toString()
      return btoa(ctr)
  }


  public decrypt(str: string) {
      let ret = CryptoJS.AES.decrypt(atob(str), this.key).toString(CryptoJS.enc.Utf8)
      return ret;
  }
}

class BlowFishCipher implements Cipher {
  private iv = 'Ow9yAH9S' 
  private mode = Blowfish.MODE.CBC;

  constructor(private key : string) {}

  encrypt(str: string): string {
      let bf = new Blowfish(this.key, this.mode);
      bf.setIv(this.iv);
      let encrypted = bf.encode(str.toString())
      let encStr = Buffer.from(encrypted.buffer).toString('base64');
      let buffer = Buffer.from(encStr);
      return buffer.toString('base64')
  }

  decrypt(str: string): string {
      let encrypted = Buffer.from(str, 'base64')
      encrypted = Buffer.from(encrypted.toString(), 'base64')
      const bf = new Blowfish(this.key, this.mode)
      bf.setIv(this.iv);
      return bf.decode(encrypted, Blowfish.TYPE.STRING)
  } 
}

class HmacCipher implements Cipher {
  constructor(private key: string) {}

  encrypt(str: string): string {
    return crypto
      .createHmac("sha256", this.key)
      .update(str)
      .digest("hex");
      
  }

  decrypt(str: string): string {
    throw new Error('Method not implemented.');
  }

}