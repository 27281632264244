import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { AppCrypto, acceptedPaymentCryptoStringList } from 'src/app/modules/cryptocurrencies/app-cryptos';
import { CryptoValueFetcherService } from 'src/app/modules/cryptocurrencies/services/crypto-value-fetcher.service';
import { AppWeb3Service } from 'src/app/web3-services/app-web3.service';
import { environment } from 'src/environments/environment';
import { AwaitingPaymentModalComponent } from './modals/awaiting-payment-modal/awaiting-payment-modal.component';
import { BtcModalComponent } from './modals/btc-modal/btc-modal.component';
import { ConfirmAddressModalComponent } from './modals/confirm-address-modal/confirm-address-modal.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { DctSignInComponent } from './modals/dct-sign-in/dct-sign-in.component';
import { EmailModalComponent } from './modals/email-modal/email-modal.component';
import { ExternalReceiptModalComponent } from './modals/external-receipt-modal/external-receipt-modal.component';
import { CurrencyValuesValidatorService, minValueUsd, } from './services/currency-values-validator.service';
import { Receipt, ExternalTransferService } from './services/external-transfer.service';

const defaultAutorefreshTimerValue = 20;
const confirmWalletAddressDescription = "Due to popular demands & requests from majority of DCT members, we have already added your DCT JNX wallet address!\nAfter successful purchase, JNX amount will be available in the same wallet address"

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
  paymentMethods = acceptedPaymentCryptoStringList;

  currentPaymentMethod: AppCrypto = "BNB"

  currentCurrencyValue = 1

  currentJanexValue = 1;

  currencyImage = bnbCurrencyIcon;

  currentCurrencyValueInUsd = 1;

  currentJanexValueInUsd = minValueUsd;

  gettingTargetWalletAddress = false;

  fetchingUsdValues = false;

  userJanexWalletAddress = "";

  isLastWalletAddressAvailable = false;

  janexValueErrorMessage?: string;

  cannotFetchValues = false;

  autorefreshTimerValue = 0;

  constructor(
    private clipboard: ClipboardService,
    private toastr: ToastrService,
    private janexApi: JanexApiService,
    private cdr: ChangeDetectorRef,
    private web3Service: AppWeb3Service,
    private modalService: NgbModal,
    private cryptoValueFetcher: CryptoValueFetcherService,
    private currencyValuesValidator: CurrencyValuesValidatorService,
    private externalTrasferService: ExternalTransferService,
    private activatedRoute: ActivatedRoute
  ) { }

  get ratio(): number {
    return this.currentCurrencyValueInUsd / this.currentJanexValueInUsd;
  }

  async ngOnInit() {
    await this.changePaymentMethod(this.currentPaymentMethod);
    this.updateCurrencyValuesInUsdEveryMinute();
    await this.checkAndProceedWithExternalReceipt();
  }

  private async checkAndProceedWithExternalReceipt() {
    const externalTrasfer = this.externalTrasferService.getExternalTransferIfExistsInParams(this.activatedRoute.snapshot.queryParams)
    if (externalTrasfer) {
      await this.processExternalTransfer(externalTrasfer);
    }
  }

  private async processExternalTransfer(externalTransfer: Receipt) {
    let modalRef = this.modalService.open(ExternalReceiptModalComponent);
    modalRef.componentInstance.receipt = externalTransfer;
    let result = await modalRef.result
    this.handleExternalReceiptModalResult(externalTransfer, result)
  }

  private async handleExternalReceiptModalResult(receipt: Receipt, result: any) {
    if (result) {
      const janexAmount = this.web3Service.checkAndFixNumberOfDecimals(receipt.janexAmount);
      const currencyAmount = this.getCurrencyAmountAfterFixingDecimals(result.currency, result.currencyAmount)

      if (result.currency === 'BTC') {
        let ref = this.modalService.open(BtcModalComponent);
        ref.componentInstance.email = receipt.email;
        ref.componentInstance.disableEmailInput = true;
        ref.componentInstance.totalAmountInDollars = currencyAmount * result.currencyValueUsd;
        ref.componentInstance.janexAmount = janexAmount
        ref.componentInstance.userJanexWallet = receipt.userWalletAddress
      } else {
        this.proceedWithPaymentWithCorrectedInfo({
          emailAddress: receipt.email,
          currencyAmount: currencyAmount,
          janexAmount: janexAmount,
          targetWalletAddress: receipt.targetWalletAddress,
          userWalletAddress: receipt.userWalletAddress,
          paymentMethod: result.currency
        }, {
          redirectionUrl: receipt.redirectionUrl,
          redirectionUrlText: 'Back to DCT Backoffice'
        },
          'dct')
      }
    }
  }

  private checkIfPreviousWalletHasBeenUsed() {
    this.isLastWalletAddressAvailable = this.getLastWalletAddressFromLocalStorage() != null;
    if (this.isLastWalletAddressAvailable) {
      this.userJanexWalletAddress = this.getLastWalletAddressFromLocalStorage() ?? '';
    }
  }

  onChangeWalletAddress() {
    this.userJanexWalletAddress = "";
  }

  onWalletAddressChanged(walletAddress: string) {
    this.userJanexWalletAddress = walletAddress
  }

  updateCurrencyValuesInUsdEveryMinute() {
    interval(60 * 1000).subscribe(x => this.updateUsdValuesAndReflectOnFields())
  }

  openPurchasePackageModal(redirection?: { redirectionUrl: string, redirectionUrlText: string }) {
    let modalRef = this.modalService.open(ConfirmationModalComponent)
    if (redirection) {
      modalRef.componentInstance.redirectionUrl = redirection.redirectionUrl
      modalRef.componentInstance.redirectionUrlText = redirection.redirectionUrlText
    }
  }

  async onBuyPressed() {
    if (!this.currencyValuesValidator.validateJanex(this.currentJanexValue, this.currentJanexValueInUsd)) {
      let emailAndAddress = await this.getEmailAndAddressFromDctAccount();
      if (emailAndAddress && await this.confirmJanexWalletAddress(emailAndAddress.wallet, confirmWalletAddressDescription)) {

        if (this.currentPaymentMethod === 'BTC') {
          this.onBuyBtc(emailAndAddress.wallet, emailAndAddress.email);
        } else {
          this.onBuyNonBtcCurrency(emailAndAddress.wallet, emailAndAddress.email);
        }
      }
    }
  }

  private async confirmJanexWalletAddress(address: string, description?: string): Promise<boolean> {
    let modalRef = this.modalService.open(ConfirmAddressModalComponent)
    modalRef.componentInstance.walletAddress = address;
    modalRef.componentInstance.description = description;
    let result = (await modalRef.result) as boolean | undefined;
    return result ?? false;
  }

  private async getEmailAndAddressFromDctAccount() {
    let ref = this.modalService.open(DctSignInComponent)
    return await ref.result;
  }

  async updateUsdValuesAndReflectOnFields() {
    await this.updateCurrencyUsdValues();
    this.onCurrencyValueChanged();
  }

  private getLastWalletAddressFromLocalStorage(): string | null {
    return localStorage.getItem(environment.purchaseLastWalletAddressLocalStorageKey)
  }

  private async onBuyBtc(walletAddress: string, email: string = '',) {
    let ref = this.modalService.open(BtcModalComponent);
    ref.componentInstance.totalAmountInDollars = this.currentCurrencyValue * this.currentCurrencyValueInUsd;
    ref.componentInstance.janexAmount = this.getJanexAmountAfterFixingDecimals()
    ref.componentInstance.userJanexWallet = walletAddress
    ref.componentInstance.emailAddress = email;
  }

  private async onBuyNonBtcCurrency(walletAddress: string, emailAddress: string = '') {

    if (emailAddress) {
      let userWalletAddress = walletAddress;
      let fixedCurrencyAmount = this.getCurrencyAmountAfterFixingDecimals(this.currentPaymentMethod, this.currentCurrencyValue)
      let fixedJanexAmount = this.getJanexAmountAfterFixingDecimals()
      let targetWalletAddress = await this.getTargetWalletAddress(fixedJanexAmount)

      if (targetWalletAddress) {
        this.proceedWithPaymentWithCorrectedInfo({
          emailAddress: emailAddress,
          currencyAmount: fixedCurrencyAmount,
          janexAmount: fixedJanexAmount,
          targetWalletAddress: targetWalletAddress,
          userWalletAddress: userWalletAddress,
          paymentMethod: this.currentPaymentMethod
        })
      }
    }
  }

  private async proceedWithPaymentWithCorrectedInfo(
    info: { emailAddress: string, userWalletAddress: string, targetWalletAddress: string, currencyAmount: number, janexAmount: number, paymentMethod: AppCrypto },
    redirection?: { redirectionUrl: string, redirectionUrlText: string },
    source: 'dct' | 'jnx' = 'jnx') {
    let invoiceId = await this.createInvoice(info.emailAddress, info.userWalletAddress, info.targetWalletAddress, info.currencyAmount, info.janexAmount, info.paymentMethod, source);
    if (invoiceId) {
      let result = await this.openAwaitingPaymentModal(info.janexAmount, info.currencyAmount, info.targetWalletAddress, invoiceId, info.paymentMethod);

      if (result === 'success') {
        this.openPurchasePackageModal(redirection);
      } else if (result === 'error') {
        this.clipboard.copy(invoiceId);
        let errorMessage = "An unknown error has occurred. If this error caused an incomplete transaction, please contact us with this invoice id. Your invoice id has been copied to clipboard."
        let errorTitle = `Error on invoice: ${invoiceId}`
        this.toastr.error(errorMessage, errorTitle, {
          disableTimeOut: true,
        })
      }
    }
  }

  private async getUserEmailAddress(): Promise<string | undefined> {
    let ref = this.modalService.open(EmailModalComponent)
    return await ref.result
  }

  private getCurrencyAmountAfterFixingDecimals(paymentCurrency: AppCrypto, amount: number) {
    switch (paymentCurrency) {
      case 'BNB':
      case 'BTC':
      case 'BUSD':
        return amount = this.web3Service.checkAndFixNumberOfDecimals(amount)

      case 'USDT':
        return amount = this.web3Service.checkAndFixNumberOfDecimals(amount, 6)
    }

    return amount;
  }

  private getJanexAmountAfterFixingDecimals() {
    return this.web3Service.checkAndFixNumberOfDecimals(this.currentJanexValue)
  }

  private async getTargetWalletAddress(cryptoAmount: number): Promise<string> {
    this.gettingTargetWalletAddress = true;
    try {
      let targetWalletAddress: string = (await this.janexApi.getEmptyWallet(cryptoAmount.toString())).data;
      targetWalletAddress = `0x${targetWalletAddress}`

      this.gettingTargetWalletAddress = false;
      return targetWalletAddress;
    } catch (err) {
      this.gettingTargetWalletAddress = false;
      throw err;
    }
  }

  private async createInvoice(emailAddress: string, userWalletAddress: string, targetWalletAddress: string, fixedCurrencyAmount: number, fixedJanexAmount: number, currency: AppCrypto, source?: 'dct' | 'jnx') {
    let params = { email: emailAddress, targetWallet: targetWalletAddress, currency: currency, currencyAmount: fixedCurrencyAmount, janexAmount: fixedJanexAmount, userWallet: userWalletAddress };
    let response = await this.janexApi.createBackendInvoice(params, source)
    return (response as any).invoiceId;
  }

  async openAwaitingPaymentModal(janexAmount: number, currencyAmount: number, walletAddress: string, invoiceId: string, paymentMethod: AppCrypto) {
    let modalRef = this.modalService.open(AwaitingPaymentModalComponent)
    modalRef.componentInstance.cryptoCurrencyValue = currencyAmount;
    modalRef.componentInstance.cryptoCurrencyValueInWei = this.convertToWei(currencyAmount, this.currentPaymentMethod);
    modalRef.componentInstance.janexValue = janexAmount;
    modalRef.componentInstance.cryptoType = paymentMethod;
    modalRef.componentInstance.targetWalletAddress = walletAddress;
    modalRef.componentInstance.invoiceId = invoiceId
    let result = await modalRef.result;
    return result;
  }

  private convertToWei(amount: number, paymentMethod: AppCrypto) {
    if (paymentMethod === 'USDT') {
      return parseInt(this.web3Service.toWei(amount.toString(), 'mwei').toString())
    } else {
      return parseInt(this.web3Service.toWei(amount.toString()).toString())
    }
  }

  async changePaymentMethod(method: AppCrypto) {
    this.currentPaymentMethod = method;
    this.currentCurrencyValue = 1;
    this.updateCurrencyIcon();
    await this.updateCurrencyUsdValues();
    this.onCurrencyValueChanged();
    this.cdr.detectChanges()
  }

  private updateCurrencyIcon() {
    switch (this.currentPaymentMethod) {
      case 'BNB':
        this.currencyImage = bnbCurrencyIcon;
        break;
      case 'BTC':
        this.currencyImage = btcCurrencyIcon;
        break;
      case 'BUSD':
        this.currencyImage = busdCurrencyIcon;
        break;
      case 'USDT':
        this.currencyImage = usdtCurrencyIcon;
        break;
    }
  }

  private async updateCurrencyUsdValues() {
    this.fetchingUsdValues = true;
    let janexUsdValue = await this.cryptoValueFetcher.getCurrencyValueInUsd('JNX')
    let currencyUsdValue = await this.cryptoValueFetcher.getCurrencyValueInUsd(this.currentPaymentMethod);
    if (janexUsdValue && currencyUsdValue) {
      this.currentJanexValueInUsd = janexUsdValue;
      this.currentCurrencyValueInUsd = currencyUsdValue;
    } else {
      this.cannotFetchValues = true;
      this.startAutorefreshCountdown();
    }
    this.fetchingUsdValues = false;
  }

  private startAutorefreshCountdown() {
    this.autorefreshTimerValue = defaultAutorefreshTimerValue;
    interval(1000).subscribe((x) => {
      if (this.autorefreshTimerValue === 0) {
        window.location.reload()
      } else {
        this.autorefreshTimerValue--;
      }
    })
  }

  onCurrencyValueChanged() {
    this.updateJanexField();
    this.updateCurrencyField();
    this.validateJanexAmount();
  }

  private updateJanexField() {
    this.currentJanexValue = this.currentCurrencyValue * this.ratio;
  }

  onJanexValueChanged() {
    this.validateJanexAmount()
    this.updateCurrencyField();
  }

  private updateCurrencyField() {
    if (this.currentPaymentMethod == 'BUSD') {
      this.currentCurrencyValue = Math.ceil(this.currentJanexValue / this.ratio * 100) / 100;
    } else {
      this.currentCurrencyValue = this.currentJanexValue / this.ratio;
    }
  }

  private validateJanexAmount() {
    this.janexValueErrorMessage = this.currencyValuesValidator.validateJanex(this.currentJanexValue, this.currentJanexValueInUsd);
  }

}

const bnbCurrencyIcon = '/assets/images/icons/binance-icon.png'
const btcCurrencyIcon = '/assets/images/icons/bitcoin-icon-logo.png'
const busdCurrencyIcon = '/assets/images/icons/busd-icon.png'
const usdtCurrencyIcon = '/assets/images/icons/tether-usdt-icon.png'
