import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { AppWeb3Service } from 'src/app/web3-services/app-web3.service';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
  email: string = '';
  
  isEmailValid?: boolean = undefined;

  paymentUrl? : string; 

  constructor(private web3 : AppWeb3Service, private modal: NgbActiveModal, private janexApi : JanexApiService) { }

  ngOnInit(): void {
  }

  cancel() {
    this.modal.close();
  }

  validateEmail() {
    this.isEmailValid = this.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
  };

  async onProceed() {
    this.modal.close(this.email);
  }

}
