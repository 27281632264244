<div class="container-fluid">
    <div class="options-items">

        <h5>To start, either:</h5>

        <button (click)="onUseConnectedWallet()" class="highlighted-btn">Use connected
            wallet</button>

        <h5>Or</h5>

        <button (click)="onEnterJanexWalletAddressPressed()" class="highlighted-btn">Enter your
            Janex wallet address</button>

        <h5>Or</h5>

        <button [disabled]="!isLastWalletAddressAvailable" (click)="onUseLastWalletAddress()"
            class="highlighted-btn">Use your last wallet address</button>
    </div>
</div>