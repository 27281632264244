import { Injectable } from '@angular/core';
import { AppCrypto } from 'src/app/modules/cryptocurrencies/app-cryptos';
import { AppWeb3Service } from 'src/app/web3-services/app-web3.service';
import { BusdContractService } from 'src/app/web3-services/smart-contracts/busd/busd-contract.service';
import { UsdtContractService } from 'src/app/web3-services/smart-contracts/usdt/usdt-contract.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentPortalService {

  constructor(private web3: AppWeb3Service, private busdContract: BusdContractService, private usdtContract: UsdtContractService) { }

  async promptUserToPay(amount: number, targetWalletAddress: string, method?: AppCrypto) {
    if (!this.web3.isConnected) {
      this.web3.initialize()
      await this.web3.connectWallet();
    }
    if (this.web3.isConnected) {
      switch (method) {
        case 'BNB':
          return await this.promptUserToPayBnb(amount, targetWalletAddress)
        // case 'BTC':

        case 'BUSD':
          return await this.promptUserToPayBusd(amount, targetWalletAddress)

        case 'USDT':
          return await this.promptUserToPayUsdt(amount, targetWalletAddress);
      }

      throw Error('Currency not recognized')
    }

    throw Error('Wallet not connected')

  }

  async promptUserToPayBnb(amount: number, targetWalletAddress: string): Promise<boolean> {
    try {
      let cryptoValue = amount
      await this.web3.switchNetwork(environment.bscChainId)
      const transactionParameters = {
        to: targetWalletAddress, // Required except during contract publications.
        from: this.web3.currentWalletAddress, // must match user's active address.
        value: this.web3.web3!.utils.toHex(this.web3.toWei(cryptoValue.toString())),
        chainId: environment.bscChainId.toString(), // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
      };

      const txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
      return true;

    } catch (err) {
      return false;
    }
  }

  async promptUserToPayBusd(amount: number, targetWalletAddress: string): Promise<boolean> {
    let cryptoValue = amount
    await this.web3.switchNetwork(environment.bscChainId)
    let contract = this.busdContract.loadBusdContract()
    let hexAmount = this.web3.web3!.utils.toHex(this.web3.toWei(cryptoValue.toString()))
    let data = contract.methods.transfer(targetWalletAddress, hexAmount).encodeABI();
    var tx = {
      from: this.web3.currentWalletAddress,
      to: environment.busdContractAddress,
      value: "0x00",
      data: data
    }
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx],
    })
  }

  async promptUserToPayUsdt(amount: number, targetWalletAddress: string): Promise<boolean> {
    let cryptoValue = amount
    await this.web3.switchNetwork(environment.ethereumChainId)
    let contract = this.usdtContract.loadUsdtContract()
    let hexAmount = this.web3.web3!.utils.toHex(this.web3.toWei(cryptoValue.toString(), 'Mwei'))
    let data = contract.methods.transfer(targetWalletAddress, hexAmount).encodeABI();
    var tx = {
      from: this.web3.currentWalletAddress,
      to: environment.usdtContractAddress,
      value: "0x00",
      data: data
    }
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx],
    })
  }
}
