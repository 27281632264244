import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-address-modal',
  templateUrl: './confirm-address-modal.component.html',
  styleUrls: ['./confirm-address-modal.component.scss']
})
export class ConfirmAddressModalComponent implements OnInit {

  @Input('description') description = "The wallet address below is where you will receive your Janex when payment is done. Please make sure this is the correct wallet address, then click 'Proceed'"

  @Input('walletAddress') walletAddress = ""

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  proceed() {
    this.activeModal.close(true)
  }

  cancel() {
    this.activeModal.close(false);
  }

}
