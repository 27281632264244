<div class="main">

    <!-- <div class="logo-section">
        <a href="https://dreamcometrue.ai/" id="logo-img">
            <img src="https://dashboard.dreamcometrue.ai/images/dct_logo_white.svg" alt="Home Page" class="big-logo">
        </a>
    </div> -->

    <div class="landing-section flex">

        <div class="ls-left">
            <div class="ls-l-box text-left">
                <h2 class="font-bold heading">Launching <span class="text-blue">Event</span></h2>
                <img class="line" src="https://dashboard.dreamcometrue.ai/images/events/line.png" />

                <p>The Launching Event of <span class="text-nowrap">DREAM COME TRUE</span> is a once-in-a-lifetime opportunity.</p>

                <p>Aiming to lead our way to innovation and advances in Fin-Tech. We are set to leap into the future like no other.</p>

                <p>Joining our launching event, you get to see Dream Come True plans to achieve in the future.</p>

                <p class="text-blue font-semibold">In order to achieve your goals and dreams, we are hosting our first ever launching event in the City of Future, Dubai - UAE.</p>

                <p>This event is designed to enhance your self-worth. Financial Freedom is available to those who learn about it and work for it.</p>

                <p>Your network is your net worth. We invite you to meet the legends for inspiration and learn from the best in the industry. </p>

                <p class="text-blue font-medium">Secure your tickets to get the awards and an opportunity to win the mega prize (Brand New Mercedes Benz).</p>

                <div class="ticket-btn-box text-center">
                    <p class="font-italic font-medium">
                        <!-- @if($ticket_amount < 30)
                            Early bird ticket for {{$ticket_amount}} PA only!
                        @else
                            Grab now for {{$ticket_amount}} PA only!
                        @endif -->
                        Early bird ticket for 20 PA only!
                    </p>
                    <!-- <a class="ticket-btn text-uppercase font-bold" href="{{route('userpanel.events', ['method' => 'ticket'])}}">get tickets</a> -->
                    <a class="ticket-btn text-uppercase font-bold" href="https://dashboard.dreamcometrue.ai/userpanel/events/ticket">get tickets</a>
                    <!-- <p class="animate">Hurry! Only {{$total_ticket_left}} tickets left!</p> -->
                    <p class="animate">Hurry! Only few tickets left!</p>
                </div>
            </div>

        </div>

        <div class="ls-right text-center">

            <iframe width="560" height="315" src="https://www.youtube.com/embed/J5kKM6m8Nyg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <div class="ls-r-box">
                <p>Everyone can join the <span class="text-blue">Dream Come True Official Launching Event.</span> <br/> Yes! You just need to have an Active DCT Educational Package.</p>

                <p>That's how easy to be part of our Official Launching Event!</p>

                <p>What are you waiting for? <span class="text-blue">Secure your ticket NOW!</span> Limited tickets only!</p>
            </div>

            <div class="ls-r-clock-box">
                <h3 class="font-bold text-uppercase">event will start in</h3>

                <div id="clock" class="time-count flex">
                    <div class="time-entry days"><span id="days">41</span> Days</div>
                    <div class="time-entry hours"><span id="hours">11</span> Hours</div>
                    <div class="time-entry minutes"><span id="minutes">13</span> Minutes</div>
                    <div class="time-entry seconds"><span id="seconds">32</span> Seconds</div>
                </div>
            </div>

            <div class="itinerary-section">
                <div class="itinerary-box">
                    <div class="ib-left">
                        <span>day 1</span>
                        <span>November 14, 2022</span>
                    </div>
                    <div class="ib-right">
                        <h4>1. Welcome Dinner Meeting; Attire: Casual</h4>
                    </div>
                </div>

                <div class="itinerary-box">
                    <div class="ib-left">
                        <span>day 2</span>
                        <span>November 15, 2022</span>
                    </div>
                    <div class="ib-right">
                        <h4>1. Full-Day Training; Attire: Corporate</h4>
                        <h4>2. Corporate Dinner; Attire: Corporate</h4>
                    </div>
                </div>

                <div class="itinerary-box">
                    <div class="ib-left">
                        <span>day 3</span>
                        <span>November 16, 2022</span>
                    </div>
                    <div class="ib-right">
                        <h4>1. Lunch Corporate Meeting; Attire: Casual</h4>
                        <h4>2. The Grand GALA night; Attire: Evening gown for Ladies and tuxedo for Gentlemen</h4>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
