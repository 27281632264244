<div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="modal-title">Thank you for purchasing Janex!</h4>
    <div class="modal-body">
        <div class="modal-body-items">
            <h6 class="btc-warning-message">Please be aware that transactions require a number of confirmations. To avoid delay, please have enough currency in your wallet before creating the invoice. Once these confirmations are done, you will instantly receive your required JNX.</h6>
            <h6 class="enter-email-label">Please enter your DCT credentials below to proceed:</h6>
            
            <div class="email-field-container field">
                <input type="text" class="username-field-input" [(ngModel)]="username"
                    placeholder="username">
            </div>
            
            <div class="email-field-container field">
                <input type="password" class="username-field-input" [(ngModel)]="password"
                    placeholder="password">
            </div>
            
            <button type="button" class="highlighted-btn"
                (click)="onSignIn()">I understand, proceed with payment</button>


        </div>
    </div>
    <div class="d-flex justify-content-around align-items-center footer">
        <button type="button" class="highlighted-btn" (click)="cancel()">Cancel</button>
    </div>
</div>