import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';

@Component({
  selector: 'app-dct-sign-in',
  templateUrl: './dct-sign-in.component.html',
  styleUrls: ['./dct-sign-in.component.scss']
})
export class DctSignInComponent implements OnInit {
  @Input('password') password: string = '';

  @Input('username') username: string = '';

  showPassword = false;

  loggingIn = false;

  constructor(private toastr : ToastrService, private janexApi: JanexApiService, private modal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  onSignIn() {
    if (this.validateInputs()) {
      this.signIn()
    }
  }

  private validateInputs() {
    let valid = this.password.length > 0 &&  this.username.length > 0;
    if(!valid) {
      this.toastr.warning('Email and password fields should not be empty')
    }
    return valid;
  }

  private async signIn() {
    this.loggingIn = true;
    try {
      let response = await this.janexApi.signInToDct(this.username, this.password);

      if(response.status === "success") {
        this.modal.close(response)
      } else if (response.status === "error") {
        this.toastr.warning(response.msg)
      }
      
    } catch (err) {
      this.toastr.error('Connection error. Please make sure you have an active internet connection and try again.')
    }

    this.loggingIn = false;
  }

  cancel() {
    this.modal.close()
  }
}
