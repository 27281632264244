<div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="modal-title">DCT Network Receipt Created</h4>
    <div class="modal-body">
        <div class="modal-body-items">

            <!-- STEP ONE -->
            <div class="text-center" *ngIf="step === firstStep">
                <p>A receipt from DCT Network has been created. Press "PROCEED" to process payment, or press "CANCEL" to go back to DCT Network.</p>
            </div>

            <!-- STEP TWO -->
            <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="step === firstStep+1">
                <h5>Please select a payment currency:</h5>

                <div style="height: 40px"></div>

                <!--  (click)="selectedCurrency = 'BNB'" -->
                <div class="d-flex flex-column flex-md-row gap-4 justify-content-center align-items-center">
                    <!-- <button [disabled]="true" class="currency-button highlighted-btn" href="#"
                        role="button">
                        <div class="currency-item-row">
                            <div>BNB</div>
                            <img class="crypto-icon" src="/assets/images/icons/binance-icon.png">
                        </div>
                    </button> -->

                    <button (click)="selectedCurrency = 'BUSD'" class="currency-button highlighted-btn" href="#"
                        role="button">
                        <div class="currency-item-row">
                            <div>BUSD</div>
                            <img class="crypto-icon" src="/assets/images/icons/busd-icon.png">
                        </div>
                    </button>

                    <!-- (click)="selectedCurrency = 'BTC'"  -->
                    <!-- <button [disabled]="true" class="currency-button highlighted-btn" href="#"
                        role="button">
                        <div class="currency-item-row">
                            <div>BTC</div>
                            <img class="crypto-icon" src="/assets/images/icons/bitcoin-icon-logo.png">
                        </div>
                    </button> -->
                </div>

                <div style="height: 40px"></div>

                <div class="d-flex justify-content-center align-items-center gap-2">
                    <h6>Currently selected: </h6>
                    <div class="current-currency-container currency-item-row field">
                        <div>{{selectedCurrency}}</div>
                        <img class="crypto-icon" [src]="selectedCurrencyImage">
                    </div>
                </div>

                <div style="height: 40px"></div>
            </div>

            <!-- STEP THREE -->
            <div class="" *ngIf="step === lastStep">
                <p>Please confirm the follow information then press "Proceed":</p>

                <div class="d-flex flex-column gap-4">
                    <div class="info-item field">
                        <h6 class="title-color">Janex amount:</h6>
                        <h6>{{receipt!.janexAmount}}</h6>
                    </div>
                    <div class="info-item field">
                        <h6 class="title-color">Payment currency:</h6>
                        <h6>{{selectedCurrency}}</h6>
                    </div>
                    <div class="info-item field">
                        <h6 class="title-color">Currency amount:</h6>
                        <h6>{{currencyAmount}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-around align-items-center footer">
        <button type="button" class="highlighted-btn" (click)="back()">{{canGoBack ? 'Back' : 'Cancel'}}</button>
        <button type="button" class="highlighted-btn" (click)="next()">Proceed</button>
    </div>
</div>