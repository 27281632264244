// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  encryptionKey: "uT0ZyTnKKXAwEdtRwj8zCVAfrBnJJ9CW",
  hmacEncryptionKey: "f83gbxz83nyhnb365ffjfdiqwf",
  janexContractAddress: "0x252facD4EFABb68b4dC3319b1D7cc9166C04b587",


  janexHomePage: "frontend.janex.finance",

  purchaseLastWalletAddressLocalStorageKey: "last_purchase_address",


  // BSC
  bscApiBaseUrl: "https://api-testnet.bscscan.com/api",
  encryptedBscApiKey: "VTJGc2RHVmtYMThheWhqUldRRjRKakJDaFFIbWRDbHNMaGxYRkJpMFAyd29GclVDekNlZDViUlNEeXpiWWFsNkVPbWNIejlKUTRFUUsxUFkzcjRCOHc9PQ==",
  bscChainId: 0x61,
  bscProviderUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  bscNetworkUrl: 'https://testnet.bscscan.com/',
  
  // Coingecko
  coingeckoBaseUrl: "https://api.coingecko.com/api/v3",

  // Pancakeswap
  pancakeswapBaseUrl: 'https://api.pancakeswap.info/api/v2',

  // Ethereum
  ethereumChainId: 0x4,
  ehtereumHttpProviderUrl: "https://rinkeby.infura.io/v3/11c364f0d08d449dac4ed9e6b45ac1b1",

  // BUSD
  //busdContractAddress: "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
  busdContractAddress: "0xa3Bc0E7e46C32bf9380dFC64a78b68A4Ba230e1b",

  // USDT
  usdtContractAddress: "0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b",

  // dct Backend
  dctBaseUrl: "https://lunarstage.dctlab.net/api/v1/common/janex",
  dctApiUrl: "https://lunarstage.dctlab.net/api/v1",
  // dctBaseUrl: "http://127.0.0.1:8000/api/v1/common/janex",
  // dctApiUrl: "http://127.0.0.1:8000/api/v1",
  
  dctEncryptedToken: "VTJGc2RHVmtYMS9SY2VJMGhMNDVTSE1wUHN0RTJObGgxR2Ztczg2NnRSdGNYQVZ6TGJCbk1lalRmb2d2bW81SWphdFdtd0N2TnBVNkd1bTdQM1VDYVE9PQ==",
  
  // Janex Backend
  janexBaseUrl: "https://backend.janex.finance/v1",
  janexEncryptedToken: "VTJGc2RHVmtYMS9SY2VJMGhMNDVTSE1wUHN0RTJObGgxR2Ztczg2NnRSdGNYQVZ6TGJCbk1lalRmb2d2bW81SWphdFdtd0N2TnBVNkd1bTdQM1VDYVE9PQ==",

  // Values that shouldn't be here

  // Min and max values in purchase page
  minPurchaseValueInUsd: 20,
  maxPurchaseValueInUsd: 50200,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
