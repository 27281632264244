import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import {CoingeckoCurrencyId} from './coingecko-models'

@Injectable()
export class CoingeckoApiService {

  constructor(private http : HttpClient) { }

  public async getCurrencyPriceInUsd(currencyId : CoingeckoCurrencyId) {
    const requestPath = `${environment.coingeckoBaseUrl}/simple/price`
    let queryParams = new HttpParams()
      .append('ids', currencyId)
      .append('vs_currencies', 'usd');

    let obs = this.http.get(requestPath, {
      params: queryParams,
    })

    return lastValueFrom(obs)
  }
}
