import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppWeb3Service } from '../../web3-services/app-web3.service';
import { ConnectButtonModalComponent } from './connect-button-modal/connect-button-modal.component';

@Directive({
  selector: '[appConnectWalletButton]'
})
export class ConnectWalletButtonDirective {
  @Output("walletConnected") walletConnected : EventEmitter<boolean> = new EventEmitter();
  
  @Output("addressChanged") addressChanged : EventEmitter<string> = new EventEmitter();

  constructor(private modalSerivce : NgbModal, private web3 : AppWeb3Service) {
    this.listenToWalletState();
    this.listenToWalletAddress();
  }

  @HostListener("click", ['$event.target']) onClick() {
    if(this.web3.hasEthereumInstance()) {
      this.web3.initialize();
      this.web3.connectWallet();
    } else {
      this.showModal();
    }
  }


  private listenToWalletState() {
    this.web3.isConnectedObs.subscribe({
      next: (connected) => {
          this.walletConnected.emit(connected);    
      }
    })
  }

  private listenToWalletAddress() {
    this.web3.walletAddressObs.subscribe({
      next: (address) => {
          this.addressChanged.emit(address);    
      }
    })
  }

  showModal() {
    this.modalSerivce.open(ConnectButtonModalComponent);
  }
}
