import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { DctLoginComponent } from '../../../modals/dct-login/dct-login.component';

@Component({
  selector: 'app-combined-report',
  templateUrl: './combined-report.component.html',
  styleUrls: ['./combined-report.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatTableModule, CommonModule, MatPaginatorModule],
})
export class CombinedReportComponent {
  txnNumber: string | null = null;
  element: any;
  // dataSource: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private route: ActivatedRoute,
    private janexApi: JanexApiService,
    private modalService: NgbModal,
    // private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.txnNumber = this.route.snapshot.paramMap.get('txnNumber');
    this.getAssetDetails();
  }

  displayedColumns: string[] = [
    'date',
    'day',
    'invested_total_amount',
    'percentage_of_the_day',
    'reward_amount_of_the_day',
    'total_reward_percentage',
    'txn_no',
  ];

  formatColumnName(column: string): string {
    // Replace underscores with spaces and convert to uppercase
    // return column.replace(/_/g, ' ').toUpperCase();
    if (column === 'date') {
      return 'Date';
    } else if (column === 'day') {
      //Elapsed, Accumulated,
      return 'Days Progressed';
    } else if (column === 'invested_total_amount') {
      return 'Total Amount';
    } else if (column === 'percentage_of_the_day') {
      return 'Percentage / Day';
    } else if (column === 'reward_amount_of_the_day') {
      return 'Reward Amount / Day';
    } else if (column === 'total_reward_percentage') {
      return 'Total Reward (%)';
    } else if (column === 'txn_no') {
      return 'Transaction No.';
    } else {
      return column;
    }
  }

  formatCellValue(column: string, value: any): string {
    // Add extra labels to the values based on the column name
    switch (column) {
      case 'invested_total_amount':
        return `${value} JNX`;
      case 'percentage_of_the_day':
        return `${value} %`;
      case 'reward_amount_of_the_day':
        return `${value} JNX`;
      case 'total_reward_percentage':
        return `${value} %`;
      case 'day':
        return this.addDaySuffix(value);
      default:
        return value;
      // return value.toString();
    }
  }

  addDaySuffix(day: number): string {
    if (day <= 0) {
      return 'N/A';
    }

    if (day >= 11 && day <= 13) {
      return day + 'th';
    }

    // switch (day % 10) {
    //   case 1:
    //     return day + 'st Day' ;
    //   case 2:
    //     return day + 'nd Day';
    //   case 3:
    //     return day + 'rd Day';
    //   default:
    //     return day + 'th Day';
    // }

    const suffixes = ['th Day', 'st Day', 'nd Day', 'rd Day'];
    const suffixIndex = day % 10 <= 3 ? day % 10 : 0;

    return day + suffixes[suffixIndex];
  }

  async getAssetDetails() {
    try {
      const response = await this.janexApi.assetRewardCombinedReport();
      //   console.log(
      //     'response.data \nresponse.data \nresponse.data \nresponse.data \n',
      //     response.data
      //   );
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
    } catch (error: any) {
      if (error.status === 401) {
        this.modalService.open(DctLoginComponent);
      } else {
        // this.toastr.error(error?.error?.message);
        this.router.navigate(['/asset-reward/report']);
        // console.log(error.message);
      }
    }
  }
}
