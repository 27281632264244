import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input("redirectionUrl") redirectionUrl? : string;
  
  @Input("redirectionUrlText") redirectionUrlText? : string;

  constructor(public activeModal: NgbActiveModal) { }

  goHome() {
    window.location.href = `https://${environment.janexHomePage}`
  }

  purchaseTickets() {
    window.location.href = 'https://dashboard.dreamcometrue.ai/login?source=ticket'
  }

  purchasePackages() {
    window.location.href = 'https://dashboard.dreamcometrue.ai/login?source=janex'
  }

  ngOnInit(): void {
  }

}
