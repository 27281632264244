import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { AppWeb3Service } from '../../app-web3.service';
import { janexAbi } from './janex-model';

@Injectable({
  providedIn: 'root'
})
export class JanexContractService {

  loadJanexContract() {
    return new this.web3Service.web3!.eth.Contract(janexAbi, environment.janexContractAddress);
  }
  constructor(private web3Service: AppWeb3Service) { }

  /**
   * @param amountToWithdraw SHOULD NOT be converted to Wei before passing
   * @returns 
   */
  async authorizeTransaction(callerAddress: string, amountToWithdraw: string) {
    let convertedAmount = this.web3Service.toWei(amountToWithdraw);
    let contract = this.loadJanexContract()
    let method = contract.methods.approve(environment.janexContractAddress, convertedAmount)

    let gas = await method.estimateGas({from: callerAddress, value: null})

    let response = await method.send({from: callerAddress, value: null, gas: gas})
    return response;
  }

  async transferJanex(from: string, to: string, amount: string) {
    let contract = this.loadJanexContract()
    let hexAmount = this.web3Service.web3!.utils.toHex(this.web3Service.toWei(amount))
    let data = contract.methods.transfer(to, hexAmount).encodeABI();
    var tx = {
      from: from,
      to: environment.janexContractAddress,
      value: "0x00",
      data: data
    }
    return await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx],
    })
  }

  async transferSignedJanex(from: string, fromPrivateKey: string, to: string, amount: string) {
    const web3 = new Web3(new Web3.providers.HttpProvider(environment.bscProviderUrl));
    let contract = this.loadJanexContract()
    var hexAmount = web3.utils.toHex(web3.utils.toWei(amount));
    var data = contract.methods.transfer(to, hexAmount).encodeABI();
    let initTx = this.buildInitialTransaction(from, data)
    let gasTx = await this.estimateGasForInitialTransaction(initTx, web3)
    let signedTx = await this.signTransaction(fromPrivateKey, gasTx, web3);
    let res = await web3.eth.sendSignedTransaction(signedTx.rawTransaction!)
    return res
    
  }

  private buildInitialTransaction(from: string, data: any) {

    var tx = {
      from: from,
      to: environment.janexContractAddress,
      value: "0x00",
      data: data
    }
    return tx;
  }

  async estimateGasForInitialTransaction(tx: any, web3: Web3) {
    var gas = await web3.eth.estimateGas(tx);

    var txWithGas = {
      from: tx.from,
      to: tx.to,
      value: tx.value,
      data: tx.data,
      gas: gas
    }

    return txWithGas
  }

  async signTransaction(privateKey: string, tx: any, web3: Web3) {
    var signedTx = await web3.eth.accounts.signTransaction(tx, privateKey)

    return signedTx;
  }

}
