<div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="modal-title">Thank you for purchasing Janex!</h4>
    <div class="modal-body">
        <div class="modal-body-items">
            <h6 class="btc-warning-message">Please be aware that transactions require a number of confirmations. To avoid delay, please have enough currency in your wallet before creating the invoice. Once these confirmations are done, you will instantly receive your required JNX.</h6>
            <h6 class="enter-email-label">Please enter your email below to proceed:</h6>
            <div class="email-field-container field">
                <input (input)="validateEmail()" type="email" class="email-field-input" [(ngModel)]="email" email
                    placeholder="example@somemail.com">
            </div>

            <h6 *ngIf="isEmailValid === false" class="btc-warning-message"><span
                    style="color: red;">**</span><span>Email address invalid</span></h6>

            <div *ngIf="isEmailValid !== false" style="min-height: 25px;"></div>

            <button *ngIf="!paymentUrl" [disabled]="isEmailValid !== true" type="button" class="highlighted-btn"
                (click)="onProceed()">I understand, proceed with payment</button>

            <a [href]="paymentUrl" *ngIf="paymentUrl" class="highlighted-btn">
                Go to Payment Page
            </a>

        </div>
    </div>
    <div class="d-flex justify-content-around align-items-center footer">
        <button type="button" class="highlighted-btn" (click)="cancel()">Cancel</button>
    </div>
</div>