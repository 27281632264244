import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
// import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MoralisApiService {

  constructor(private http: HttpClient) { }

  public async getBinanceChainTokenValueInUsd(address: string) {
    let url = `https://deep-index.moralis.io/api/v2/erc20/${address}/price?chain=bsc&exchange=PancakeSwapv2`
    let obs = this.http.get(url, {
      headers: {
        // Accept: "application/json",
        "X-API-Key": "CFdzB8OlMp28Bg7r9ALkIR50J21DqNccIKpok2oS9ICNWGDUZ66MrD7gPj447OWm",
      },
    });
    return lastValueFrom(obs)
  }
}
