<!-- Header Section -->
<div class="header-wrapper section-wrapper">
	<div class="container flex">
		<div class="header-mobile-wrapper">
			<div class="header-mobile">
				<div class="flex justify-between items-center">
					<a href="#">
						<img src="/assets/images/v2/images/logo.png" />
					</a>
					<button class="hamburger animate plain" (click)="toggleMobileMenu()">
						<span></span>
					</button>
				</div>
				<ul class="header-menu flex items-center">
					<li class="header-menu-li"><a href="#">Home</a></li>
					<li class="header-menu-li"><a href="https://hrd-2.gitbook.io/janex-token/" target="_blank">Whitepaper</a></li>
					<li class="header-menu-li"><a href="#economy">Economy</a></li>
					<li class="header-menu-li"><a href="#tokenomics">Tokenomics</a></li>
					<li class="header-menu-li"><a href="#roadmap">Roadmap</a></li>
					<li class="header-menu-li">
						<button 
							routerLink="/asset-reward/"
							mat-raised-button 
							color="primary"
							class="button"
						>
							Go to Asset Reward
						</button>
					</li>
					<!-- <li class="header-menu-li"><a href="https://backoffice.dreamcometrue.ai/" target="_blank" class="cta">Login</a></li> -->
					<!-- <a (click)="handleAuthentication()" href="" class="cta">{{ buttonText }}</a> -->

					<button (click)="handleAuthentication()" class="cta">
						<div class="connect-wallet-text">{{ buttonText }}</div>
					</button>
				</ul>
			</div>

			<div class="header-mobile-menu-icon">
				<!-- <span class="header-mobile-menu-icon-open">open</span> -->
				<button class="hamburger animate plain" (click)="toggleMobileMenu()">
					<span></span>
				</button>
				<!-- <span class="header-mobile-menu-icon-close none">close</span> -->
			</div>
		</div>

		<header class="header flex justify-between items-center">
			<div>
				<a href="#">
					<img src="/assets/images/v2/images/logo.png" />
				</a>
			</div>
			<ul class="header-menu flex items-center">
				<li class="header-menu-li"><a href="#">Home</a></li>
				<li class="header-menu-li"><a href="https://hrd-2.gitbook.io/janex-token/" target="_blank">Whitepaper</a></li>
				<li class="header-menu-li"><a href="#economy">Economy</a></li>
				<li class="header-menu-li"><a href="#tokenomics">Tokenomics</a></li>
				<li class="header-menu-li"><a href="#roadmap">Roadmap</a></li>
				<li class="header-menu-li">
					<button 
						routerLink="/asset-reward/"
					>
						Asset Reward
					</button>
				</li>
				<!-- <li class="header-menu-li"><a href="https://backoffice.dreamcometrue.ai/" target="_blank" class="cta">Join Now</a></li> -->
			</ul>
			<!-- <a href="" class="cta header-menu-cta">Login</a> -->
			<button (click)="handleAuthentication()" class="cta header-menu-cta">
				<div class="connect-wallet-text">{{ buttonText }}</div>
			</button>
		</header>
	</div>
</div>
  
<router-outlet></router-outlet>

<!-- Footer -->
<div class="footer-wrapper section-wrapper">
	<div class="footer-section container flex flex-col items-center">

		<h2>Contact Us</h2>

		<div class="footer-social-media">
			<a target="_blank" href="https://www.facebook.com/janextoken">
				<img src="/assets/images/v2/images/facebook.svg" />
			</a>
			<a target="_blank" href="https://www.instagram.com/janextoken/">
				<img src="/assets/images/v2/images/instagram.svg" />
			</a>
			<a target="_blank" href="https://twitter.com/janextoken">
				<img src="/assets/images/v2/images/twitter.svg" />
			</a>
			<a target="_blank" href="https://discord.gg/vnHbDG3AKn">
				<img src="/assets/images/v2/images/discord.svg" />
			</a>
			<a target="_blank" href="https://t.me/janextokenofficial">
				<img src="/assets/images/v2/images/telegram.svg" />
			</a>
		</div>

	</div>
</div>