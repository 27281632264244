<section class="section">
	<!-- <div class="d-flex align-items-center justify-content-center">
      <h3 class="purchase-section-title">Asset Reward Report</h3>
    </div> -->

	<div>
		<a href="/asset-reward/report" mat-raised-button color="primary" class="cta-btn">
			Back to Report
		</a>
	</div>
	<div class="d-flex row">
		<div class="panel col-md-3 col-sm-12 mb-3">
			<div class="lp-top">
				<span [class]="handleStatus(report.status)">
					{{getStatusName(report.status_name)}}
				</span>
				<h4>{{report.amount}} JNX</h4>
				<h3>{{report.txn_no}}</h3>
				<h5>{{report.days}} Days</h5>
			</div>
			<div class="lp-middle">
				<div>
					<span>{{report.date}}</span>
					<span>Date of Lock</span>
				</div>
				<hr>
				<div>
					<span>{{report.end_date}}</span>
					<span>Reward Date</span>
				</div>
			</div>
		</div>
		<div class="panel col-md-9 col-sm-12">
			<div class="top row">
				<div class="col-lg-2 col-md-4">
					<span>Reward Percentage</span>
					<span>{{report.reward_percentage}} %</span>
				</div>
				<div class="col-lg-2 col-md-4">
					<span>Total Amount</span>
					<span>{{report.total_amount}} JNX</span>
				</div>
				<div class="col-lg-2 col-md-4">
					<span>Status</span>
					<span>{{report.status_name}}</span>
				</div>
				<div class="col-lg-6 col-md-12">
					<span>Txn Hash</span>
					<span class="text-break">{{report.txn_hash}}</span>
				</div>
			</div>
			<div class="middle row mt-3 table-wrapper">
				<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
					<!-- Columns -->
					<ng-container matColumnDef="date">
						<th mat-header-cell *matHeaderCellDef>Date</th>
						<td mat-cell *matCellDef="let element"
							[ngClass]="{'green-text': element.reward_percentage == 100, 'default-text': element.reward_percentage != 100}">
							{{element.date}} </td>
					</ng-container>
					<!--
							<ng-container matColumnDef="reward_percentage">
								<th mat-header-cell *matHeaderCellDef>Reward Percentage</th>
								<td mat-cell *matCellDef="let element">{{ element.reward_percentage }}</td>
						  	</ng-container>
						-->
					<ng-container matColumnDef="reward_percentage">
						<th mat-header-cell *matHeaderCellDef>Reward Percentage</th>
						<td mat-cell *matCellDef="let element"
							[ngClass]="{'green-text': element.reward_percentage == 100, 'default-text': element.reward_percentage != 100}">
							{{element.reward_percentage}} %</td>
					</ng-container>

					<ng-container matColumnDef="reward_amount">
						<th mat-header-cell *matHeaderCellDef>Reward Amount</th>
						<td mat-cell *matCellDef="let element"
							[ngClass]="{'green-text': element.reward_percentage == 100, 'default-text': element.reward_percentage != 100}">
							{{ element.reward_amount }} JNX</td>
					</ng-container>

					<ng-container matColumnDef="cumulative_amount">
						<th mat-header-cell *matHeaderCellDef>Amount</th>
						<td mat-cell *matCellDef="let element"
							[ngClass]="{'green-text': element.reward_percentage == 100, 'default-text': element.reward_percentage != 100}">
							{{ element.cumulative_amount }} JNX of {{ element.total_amount }} JNX</td>
					</ng-container>

					<!-- Replace 'dataSource' with your actual data array -->
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
				<!-- <div class="d-flex paginator-wrapper"> -->
					<mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
				  <!-- </div> -->
			</div>
		</div>
	</div>

</section>