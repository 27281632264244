import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { AppWeb3Service } from 'src/app/web3-services/app-web3.service';

@Component({
  selector: 'app-btc-modal',
  templateUrl: './btc-modal.component.html',
  styleUrls: ['./btc-modal.component.scss']
})
export class BtcModalComponent implements OnInit {
  @Input("totalAmountInDollars") totalAmountInDollars: number = 0;

  @Input("janexAmount") janexAmount: number = 0;

  @Input("userJanexWallet") userJanexWallet: string = "";

  @Input("email") email: string = '';

  @Input("disableEmailInput") disableEmailInput = false;

  isEmailValid?: boolean = undefined;

  loading = false;

  paymentUrl?: string;

  constructor(private modal: NgbActiveModal, private janexApi: JanexApiService) { }

  ngOnInit(): void {
  }

  cancel() {
    if (this.paymentUrl) {
      this.modal.close(true);
    } else {
      this.modal.close()
    }
  }

  validateEmail() {
    if (this.disableEmailInput) {
      this.isEmailValid = true;
    } else {
      this.isEmailValid = this.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
    }
  };

  async onProceed() {
    this.loading = true;
    let walletAddress = this.userJanexWallet;
    let url: string[] = await this.janexApi.createBtcInvoice({
      dollarAmount: this.totalAmountInDollars,
      email: this.email,
      janexAmount: this.janexAmount,
      walletAddress: walletAddress
    }) as string[];

    this.paymentUrl = url[1];
    this.loading = false;
  }

}
