<section>
    <h2 *ngIf="username != ''" class="welcome">Welcome, {{ username }}</h2>

    <mat-card class="wallet-balance">
        <!-- <mat-card-header>
            <mat-card-title>
                Janex Wallet Balance
            </mat-card-title>
            <mat-card-subtitle>JNX {{ balance }}</mat-card-subtitle>
        </mat-card-header> -->
        
        <mat-card-header>
            <mat-card-title>
                <div class="d-flex justify-content-center">
                    <img class="token-icon mb-1" mat-card-sm-image src="/assets/images/icons/janex-token-white.png">
                </div>
                <span class="balance-amount" *ngIf="balanceVisible">JNX {{ balance }}</span>
            </mat-card-title>
            <mat-card-subtitle class="balance-subtitle">
                Total Balance
            </mat-card-subtitle>
        </mat-card-header>
        
        
        <!-- <mat-card-content>
            <p>This card indeterminates progress bar.</p>
            <p>{{longText}}</p>
        </mat-card-content> -->
        <!-- <mat-card-actions class="d-flex justify-content-end"> -->
            <!-- <button mat-button>View Report</button> -->
            <!-- <a 
                target="_blank" 
                [href]="walletReportUrl"
                mat-raised-button 
                color="primary"
            >
                View Report
            </a> -->
            <!-- <a 
                routerLink="/asset-reward/report"
                mat-raised-button 
                color="primary"
            >
                Asset Reward Report
            </a>
        </mat-card-actions> -->
    </mat-card>
    <a routerLink="/asset-reward/report" class="report-link">
        <mat-card class="wallet-balance-link">
            <mat-card-actions>
                Asset Reward Report
            </mat-card-actions>
        </mat-card>
    </a>
</section>