import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppCrypto } from 'src/app/modules/cryptocurrencies/app-cryptos';
import { CryptoValueFetcherService } from 'src/app/modules/cryptocurrencies/services/crypto-value-fetcher.service';
import { Receipt } from '../../services/external-transfer.service';

@Component({
  selector: 'app-external-receipt-modal',
  templateUrl: './external-receipt-modal.component.html',
  styleUrls: ['./external-receipt-modal.component.scss']
})
export class ExternalReceiptModalComponent implements OnInit {
  firstStep = 1;

  lastStep = 3;

  receipt?: Receipt;

  step: number = this.firstStep;

  get canProceed() { return this.step < this.lastStep }

  get canGoBack() { return this.step !== this.firstStep }

  calculating = false;

  tryAgain = false;

  selectedCurrency: AppCrypto = 'BUSD'

  currencyAmount = 0;

  lastCurrencyValueInUsd = 1;

  get selectedCurrencyImage() {
    if (this.selectedCurrency === 'BNB') {
      return '/assets/images/icons/binance-icon.png'
    } else if (this.selectedCurrency === 'BUSD') {
      return '/assets/images/icons/busd-icon.png'
    } else {
      return '/assets/images/icons/bitcoin-icon-logo.png'
    }
  }

  constructor(private cryptoValueFetcher: CryptoValueFetcherService, private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.currencyAmount = this.receipt!.cryptoAmount + this.receipt!.gasFee;
  }

  back() {
    if (!this.canGoBack) {
      this.activeModal.close()
    } else {
      this.step--;
    }
  }

  async next() {
    // if (this.step === (this.lastStep - 1)) {
    //   await this.calculate();
    // }

    if (!this.tryAgain) {
      if (this.canProceed) {
        this.step++
      } else {
        this.finish()
      }
    }
  }

  // async calculate() {
  //   this.calculating = true;
  //   this.tryAgain = false;
  //   try {
      
  //     // const currencyValueUsd = await this.cryptoValueFetcher.getCurrencyValueInUsd(this.selectedCurrency)
  //     // const janexValueUsd = await this.cryptoValueFetcher.getCurrencyValueInUsd('JNX')
  //     // const ratio = currencyValueUsd! / janexValueUsd!
  //     // this.currencyAmount = this.receipt!.janexAmount;
  //     // this.lastCurrencyValueInUsd = currencyValueUsd!;
  //     this.currencyAmount = this.receipt!.cryptoAmount;

  //   } catch (err) {
  //     console.log(err);
  //     this.tryAgain = true;
  //   }
  //   this.calculating = false;
  // }

  finish() {
    this.activeModal.close({ 
      currency: this.selectedCurrency,
      currencyAmount: this.currencyAmount, 
      currencyValueUsd: this.lastCurrencyValueInUsd
    })
  }

}
