import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Cookies from 'js-cookie';
import { lastValueFrom } from 'rxjs';
import { AppCrypto } from 'src/app/modules/cryptocurrencies/app-cryptos';
import { EncryptionService } from 'src/app/modules/encryption/services/encryption.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JanexApiService {
  constructor(private http: HttpClient, private encryptor: EncryptionService) {}

  getEmptyWallet(amount: string): Promise<any> {
    let url = `${environment.dctBaseUrl}?action=empty_wallet&amount=${amount}`;

    let headers = new HttpHeaders({
      Authorization: `Bearer ${this.encryptor.aes.decrypt(
        environment.dctEncryptedToken
      )}`,
    });
    let obsResponse = this.http.get(url, {
      headers: headers,
    });
    return lastValueFrom(obsResponse);
  }

  createBackendInvoice(
    parameters: {
      email: string;
      targetWallet: string;
      userWallet: string;
      currency: AppCrypto;
      currencyAmount: number;
      janexAmount: number;
    },
    source: 'dct' | 'jnx' = 'jnx'
  ) {
    let url = `${environment.janexBaseUrl}/createJanexInvoice`;
    let body = {
      email: parameters.email,
      companyWallet: parameters.targetWallet,
      userWallet: parameters.userWallet,
      totalAmount: parameters.currencyAmount,
      currency: parameters.currency,
      janexAmount: parameters.janexAmount,
      source: source,
    };

    let headers = this.getEssentialHeaders(body);
    let obsResponse = this.http.post(url, body, { headers: headers });
    return lastValueFrom(obsResponse);
  }

  sendPaymentInfo(parameters: {
    invoiceId: string;
    paidAmount: number;
    type: 'payment_successfull' | 'partial_payment_successfull';
  }) {
    let url = `${environment.janexBaseUrl}/sendJanex`;

    let body = parameters;

    let headers = this.getEssentialHeaders(body);
    let obsResponse = this.http.post(url, body, { headers: headers });
    return lastValueFrom(obsResponse);
  }

  createBtcInvoice(parameters: {
    walletAddress: string;
    dollarAmount: number;
    email: string;
    janexAmount: number;
  }) {
    let url = `${environment.janexBaseUrl}/createInvoice`;

    let body = {
      siteUrl: `https://${environment.janexHomePage}`,
      totalAmount: parameters.dollarAmount,
      email: parameters.email,
      walletId: parameters.walletAddress,
      janexAmount: parameters.janexAmount,
    };

    let headers = this.getEssentialHeaders(body);

    let obsResponse = this.http.post(url, body, { headers: headers });
    return lastValueFrom(obsResponse);
  }

  public signInToDct(username: string, password: string) {
    let url = `${environment.dctBaseUrl}/login`;

    let body = {
      username: this.encryptor.blowfish.encrypt(username),
      password: this.encryptor.blowfish.encrypt(password),
    };

    let headers = this.getEssentialHeaders(body);

    let obsResponse = this.http.post(url, body, { headers: headers });
    return lastValueFrom(obsResponse) as Promise<any>;
  }

  private getEssentialHeaders(body?: any, cors: boolean = true) {
    let headers = new HttpHeaders();
    if (body) {
      let stringified = JSON.stringify(body);
      let bearerToken = `Bearer ${this.encryptor.hmac.encrypt(stringified)}`;
      headers = headers.append('Authorization', bearerToken);
    }

    if (cors) {
      headers = headers.append('Access-Control-Allow-Origin', '*');
      headers = headers.append(
        'Access-Control-Allow-Methods',
        'GET, POST, DELETE, PUT'
      );
    }

    return headers;
  }

  public async durationWallet(): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/duration_wallet`;

    const headers = this.getHeaders();

    let obsResponse = this.http.get(url, {
      headers: headers,
    });

    return lastValueFrom(obsResponse) as Promise<any>;
  }

  public async vest(body: FormData): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/vest`;

    const headers = this.getHeaders();

    let obsResponse = this.http.post(url, body, {
      headers: headers,
    });

    return lastValueFrom(obsResponse) as Promise<any>;
  }

  public async revest(body: FormData): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/revest`;

    const headers = this.getHeaders();

    let obsResponse = this.http.post(url, body, { 
      headers: headers 
    });

    return lastValueFrom(obsResponse) as Promise<any>
  }

  public assetRewardReport() {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/report`;

    const headers = this.getHeaders();

    let obsResponse = this.http.get(url, {
      headers: headers,
    });

    return lastValueFrom(obsResponse) as Promise<any>;
  }

  public signOut() {
    let url = `${environment.dctApiUrl}/logout`;

    const headers = this.getHeaders();

    let obsResponse = this.http.get(url, {
      headers: headers,
    });

    return lastValueFrom(obsResponse) as Promise<any>;
  }

  private getHeaders() {
    let headers = new HttpHeaders();

    headers = headers.append(
      'Authorization',
      `Bearer ${this.getCookieValue('auth_token')}`
    );

    return headers;
  }

  public getCookieValue(name: string): string | undefined {
    return Cookies.get('auth_token');
    /* const cookies = document.cookie.split(';')
      .map(cookie => cookie.trim())
      .reduce((acc: { [key: string]: string }, cookie) => {
        const [cookieName, cookieValue] = cookie.split('=');
        acc[cookieName] = cookieValue;
        return acc;
      }, {});
  
    return cookies[name] || null; */
  }

  public claim(id: number): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/claim`;

    const headers = this.getHeaders();

    let obsResponse = this.http.post(
      url,
      { id },
      {
        headers: headers,
      }
    );

    return lastValueFrom(obsResponse) as Promise<any>;
  }

  public assetRewardSingleReport(txnNumber: string): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/singleReport`;

    const headers = this.getHeaders();

    let obsResponse = this.http.post(
      url,
      { txnNumber },
      {
        headers: headers,
      }
    );

    return lastValueFrom(obsResponse) as Promise<any>;
  }

  public assetRewardCombinedReport(): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/report`;

    const headers = this.getHeaders();

    let obsResponse = this.http.post(
      url,
      { type: 'all-date-wise' },
      {
        headers: headers,
      }
    );

    return lastValueFrom(obsResponse) as Promise<any>;
  }

  public cancelAsset(id: number): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/cancelAsset`;

    const headers = this.getHeaders();

    let obsResponse = this.http.post(url, {id}, { 
      headers: headers 
    });

    return lastValueFrom(obsResponse) as Promise<any>
  }

  public cancelAssetTransactionCharge(): Promise<any> {
    let url = `${environment.dctApiUrl}/userpanel/janex/vestreward/cancelAssetTransactionCharge`;

    const headers = this.getHeaders();

    let obsResponse = this.http.post(url, {}, { 
      headers: headers 
    });

    return lastValueFrom(obsResponse) as Promise<any>
  }
}
