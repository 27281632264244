<div class="d-flex flex-column justify-content-center align-items-center">
  <h4 class="modal-title">Enter your Janex wallet</h4>
  <div class="modal-body">
    <div class="modal-body-items">
      <p>Please enter the wallet address you'd like to receive your purchased Janex on:</p>
      <div class="email-field-container field">
        <input [(ngModel)]="walletAddress" (input)="validateWalletAddress()" type="text" class="email-field-input"  placeholder="example: 0xfd124...">
      </div>

      <p *ngIf="walletAddressInvalid === true"><span style="color: red">** </span><span> Invalid address</span></p>

    </div>
  </div>
  <div class="d-flex justify-content-around align-items-center footer">
    <button [disabled]="walletAddressInvalid" type="button" class="highlighted-btn" (click)="confirm()">Confirm</button>
    <button type="button" class="highlighted-btn" (click)="cancel()">Cancel</button>
  </div>
</div>