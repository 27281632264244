<section class="intro-section">
  <div class="intro-section-elements">

    <div class="intro-shape-container tilter">
      <!-- <img class="blockchain-chain tilter-inner" src="/assets/images/background-images/blockchain-chain.png"> -->

      <div class="contract-add">
        <p>Janex contract address:</p>
        <div class="d-flex flex-column">
          <div (click)="copyContractAddress()" class="highlighted-btn btnFrame">
            <div (click)="copyContractAddress()" class="addressText" style="padding: 10px;">
              0xd3C117bD35025786714B56F44C93AD6DeB68B4D1</div>
            <div (click)="copyContractAddress()" class="copyToClipboard">Click to copy</div>
            <p id="contract-copied"></p>
          </div>
        </div>
      </div>

    </div>

    <div class="intro-text-container">
      <div class="intro-text-items">
        <h1 class="intro-text">JANEX</h1>
        <h3 class="intro-text">This platform allows users to quickly and securely bridge assets from any blockchain</h3>
        <!-- <button [routerLink]="'purchase'" class="highlighted-btn intro-text">
                    Buy Janex Now
                </button> -->
        <div>

          <div class="button-wrapper">
            <button 
              routerLink="/asset-reward/"
              mat-raised-button 
              color="primary"
              class="button"
            >
              Go to Asset Reward
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>
