import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BscApiService } from 'src/app/data/api/bsc/bsc-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wallet-balance',
  templateUrl: './wallet-balance.component.html',
  styleUrls: ['./wallet-balance.component.scss']
})
export class WalletBalanceComponent implements OnChanges {
  @Input('wallet') wallet = '';
  @Input('username') username = '';

  balance: string | null = null;
  walletReportUrl: string | null = '';
  balanceVisible: boolean = false;
  constructor(
    private bscApi: BscApiService
  ) {
    setTimeout(() => {
      this.balanceVisible = true;
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['wallet'] && !changes['wallet'].firstChange) {
      this.getWalletBalance();
      this.setWalletReportUrl();
    }
  }

  private async getWalletBalance() {
    try {
      const balance = await this.bscApi.getBalance(this.wallet);
      this.balance = balance; 
    } catch(error) {
      console.error("Error fetching balance:", error);
    }
  }

  private setWalletReportUrl() {
    this.walletReportUrl = `${environment.bscNetworkUrl}address/${this.wallet}#tokentxns`;
  }

}
