import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppCrypto } from 'src/app/modules/cryptocurrencies/app-cryptos';
import { AppWeb3Service } from 'src/app/web3-services/app-web3.service';
import { JanexContractService } from 'src/app/web3-services/smart-contracts/janex/janex-contract.service';
import { ClipboardService } from 'ngx-clipboard'
import { DeeplinkGeneratorService } from '../../services/deeplink-generator.service';
import { PaymentListenerService } from '../../services/payment-listener.service';
import { PaymentPortalService } from '../../services/payment-portal.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';


@Component({
  selector: 'app-awaiting-payment-modal',
  templateUrl: './awaiting-payment-modal.component.html',
  styleUrls: ['./awaiting-payment-modal.component.scss']
})
export class AwaitingPaymentModalComponent implements OnInit, OnDestroy {
  @Input('targetWalletAddress') targetWalletAddress: string = '';

  @Input('cryptoCurrencyValue') cryptoCurrencyValue: number = 0;

  @Input('cryptoCurrencyValueInWei') cryptoCurrencyValueInWei: string = '0';

  @Input('janexValue') janexValue: number = 0;

  @Input('cryptoType') cryptoType?: AppCrypto;

  @Input('invoiceId') invoiceId: string = '';

  selectedMethod: 'metamask' | 'trustwallet' | 'browser' | 'copy' = 'browser'

  loading: boolean = true;

  loadingText: string = "Awaiting transaction..."

  constructor(private janexApi: JanexApiService, private toastr: ToastrService, private clipboard: ClipboardService, private paymentListener: PaymentListenerService, private deeplinkGenerator: DeeplinkGeneratorService, private web3: AppWeb3Service, private janexContract: JanexContractService, private portal: PaymentPortalService, private activeModal: NgbActiveModal) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => { sub.unsubscribe() })
  }

  subscriptions: Subscription[] = []

  async ngOnInit() {
    this.listen()
  }

  setSelectedMethod(method: 'metamask' | 'trustwallet' | 'browser' | 'copy') {
    this.selectedMethod = method;
  }

  copyAddress() {
    this.clipboard.copy(this.targetWalletAddress)
    this.toastr.success(`Address copied to clipboard!`)
  }

  get metamaskDeeplink(): string {
    return this.deeplinkGenerator.generateForCurrency(this.cryptoType!, this.targetWalletAddress, this.cryptoCurrencyValue);
  }

  async listen() {
    let listener = await this.paymentListener.listenToPayment(this.cryptoType!, parseInt(this.cryptoCurrencyValueInWei), this.targetWalletAddress)
    let subscription = listener.subscribe({
      next: async (val) => {
        switch (val.type) {
          case 'full':
            subscription.unsubscribe();
            let janexTransferResult = await this.onFullAmountPaid();
            if (janexTransferResult) {
              this.activeModal.close('success');
            } else {
              this.activeModal.close('janex-payment-failed')
            }
            break;
          case 'partial':
            this.loadingText = "Received partial payment.."
            this.onPartialAmountPaid(val.paidAmount)
            break;
        }
      }
    })

    this.subscriptions.push(subscription)
  }

  async onPayThroughBrowser() {
    try {
      this.loading = true;
      this.loadingText = "Awaiting payment...";
      await this.portal.promptUserToPay(this.cryptoCurrencyValue, this.targetWalletAddress, this.cryptoType);
    } catch (err) {
      return
    }
  }

  async onFullAmountPaid() {
    this.loadingText = "Payment done! Transferring your janex..."
    let result = await this.janexApi.sendPaymentInfo({
      invoiceId: this.invoiceId,
      paidAmount: this.cryptoCurrencyValue,
      type: 'payment_successfull'
    })

    return (result as any).message.toLowerCase() !== 'failed'
  }

  async onPartialAmountPaid(value : number) {
    this.loadingText = "Partial Payment received..."
    let result = await this.janexApi.sendPaymentInfo({
      invoiceId: this.invoiceId,
      paidAmount: this.convertValueFromWei(value),
      type: 'partial_payment_successfull'
    })

    return (result as any).message.toLowerCase() !== 'failed'
  }

  cancel() {
    this.activeModal.close()
  }

  private convertValueFromWei(value : number) {
    if(this.cryptoType === 'USDT') {
      return parseFloat(this.web3.fromWei(value.toString(), 'mwei').toString())
    } else {
      return parseFloat(this.web3.fromWei(value.toString()).toString())
    }
  }

  onCopyExactAmount() {
    this.clipboard.copy(this.cryptoCurrencyValue.toString())
    this.toastr.success("Amount copied to clipboard!")
  }
}
