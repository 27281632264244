import { Injectable } from '@angular/core';
import { AppCrypto } from 'src/app/modules/cryptocurrencies/app-cryptos';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkGeneratorService {

  constructor() { }

  generateForCurrency(currency: AppCrypto, receiverAddress : string, amount : number) {
    switch (currency) {
      case 'BNB':
        return this.generateForBnb(receiverAddress, amount)   
        
      case 'BUSD':
        return this.generateForBusd(receiverAddress, amount)

      case 'USDT':
        return this.generateForUsdt(receiverAddress, amount);  
    }
    throw Error('Currency not recognized')
  }

  generateForBnb(toAddress : string, amount : number) {
    var base = "https://metamask.app.link/send/"
    var prefix = "pay-"
    var networkId = `${environment.bscChainId}`
    return `${base}${prefix}${toAddress}@${networkId}?value=${amount.toString()}e18`
  }

  generateForBusd(toAddress : string, amount: number) {
    return this.generateContractDeeplink({
      amount: amount,
      contractAddress: environment.busdContractAddress,
      networkId: `${environment.bscChainId}`,
      toAddress: toAddress
    })
  }

  generateForUsdt(toAddress : string, amount : number) {
    return this.generateContractDeeplink({
      amount: amount,
      contractAddress: environment.usdtContractAddress,
      networkId: `${environment.ethereumChainId}`,
      toAddress: toAddress
    })
  }

  private generateContractDeeplink(info : {networkId : string, contractAddress : string, toAddress : string, amount: number}) : string {
    var base = "https://metamask.app.link/send/";
    var prefix = "pay-"
    var operation = 'transfer'
    return `${base}${prefix}${info.contractAddress}@${info.networkId}/${operation}?address=${info.toAddress}&uint256=${info.amount}e18`
  }
}
