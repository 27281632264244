<div class="home-page">
	<!-- Banner -->
    <div class="banner-wrapper section-wrapper">
        <div class="banner-description-box container flex flex-col">

            <div class="banner-text flex flex-col items-center">
                <h1>Janex</h1>
                <p>Shaping the Best and Most Futuristic Digital Asset Experience</p>
                <!-- <a href="" target="_blank" class="cta cta-white">Go To Assets Awards</a> -->
				<button 
					routerLink="/asset-reward/"
					class="cta cta-white"
				>
					Go to Asset Reward
				</button>
                <div class="janex-contract">
                    <h4>Janex contract address:</h4>
                    <span (click)="copyContractAddress()">0xd3C117bD35025786714B56F44C93AD6DeB68B4D1</span>
					<div id="contract-copied"></div>
                </div>
            </div>

        </div>
		<div class="banner-images">
			<div class="anime-images anime-1">
				<img src="/assets/images/v2/images/bg/anime/anime_1.png" />
			</div>
			<div class="anime-images anime-2">
				<img src="/assets/images/v2/images/bg/anime/anime_2.png" />
			</div>
			<div class="anime-images anime-3">
				<img src="/assets/images/v2/images/bg/anime/anime_3.png" />
			</div>
			<div class="anime-images anime-4">
				<img src="/assets/images/v2/images/bg/anime/anime_4.png" />
			</div>
			<div class="anime-images anime-5">
				<img src="/assets/images/v2/images/bg/anime/star_1.png" />
			</div>
			<div class="anime-images anime-6">
				<img src="/assets/images/v2/images/bg/anime/star_2.png" />
			</div>
			<div class="anime-images anime-7">
				<img src="/assets/images/v2/images/bg/anime/star_3.png" />
			</div>
			<div class="anime-images anime-8">
				<img src="/assets/images/v2/images/bg/anime/star_4.png" />
			</div>
		</div>
    </div>
	
	<!-- What We Do -->
	<div class="core-wrapper section-wrapper">
	<div class="container flex flex-col">

		<div class="core-description">
			<h2 class="">Janex Core Features</h2>
		</div>

		<div class="core-card-wrapper core-card-odd flex justify-center">
			<div class="core-card">
				<div class="flex flex-col core-card-inner">
					<img src="/assets/images/v2/images/trading.svg" />
					<h3>Trading</h3>
					<p>Trade Janex with ease on multiple exchanges. Our token empowers users with a secure and intuitive way to engage in digital asset trading. Join us now and seize the future of crypto trading.</p>
				</div>
				<a href="" class="flex items-center">
					Get Started
					<img src="/assets/images/v2/images/arrow-right.svg" />
				</a>
			</div>
			<div class="core-card">
				<div class="flex flex-col core-card-inner">
					<img src="/assets/images/v2/images/payment.svg" />
					<h3>Payment Method</h3>
					<p>Choose your preferred payment method to buy Janex. We offer a variety of options to make acquiring Janex as convenient as possible. Get started today.</p>
				</div>
				<a href="" class="flex items-center">
					Get Started
					<img src="/assets/images/v2/images/arrow-right.svg" />
				</a>
			</div>
		</div>

		<div class="core-card-wrapper core-card-even flex justify-between">
			<div class="core-card">
				<div class="flex flex-col core-card-inner">
					<img src="/assets/images/v2/images/centralized.svg" />
					<h3>Centralized Exchange</h3>
					<p>Janex is listed on top centralized exchanges for your convenience. Trade with confidence on these trusted platforms and unlock the full potential of your investments.</p>
				</div>
				<a href="" class="flex items-center">
					Get Started
					<img src="/assets/images/v2/images/arrow-right.svg" />
				</a>
			</div>
			<div class="core-card">
				<div class="flex flex-col core-card-inner">
					<img src="/assets/images/v2/images/wallet.svg" />
					<h3>Wallet Transfer</h3>
					<p>Move your Janex between wallets with ease. Our streamlined transfer process ensures quick and secure transactions, making managing your tokens a breeze.</p>
				</div>
				<a href="" class="flex items-center">
					Get Started
					<img src="/assets/images/v2/images/arrow-right.svg" />
				</a>
			</div>
			<div class="core-card">
				<div class="flex flex-col core-card-inner">
					<img src="/assets/images/v2/images/asset.svg" />
					<h3>Asset Rewards</h3>
					<p>Boost your Janex holdings through Asset Reward. Earn rewards effortlessly and watch your investments grow.</p>
				</div>
				<a href="" class="flex items-center">
					Get Started
					<img src="/assets/images/v2/images/arrow-right.svg" />
				</a>
			</div>
		</div>

	</div>
	</div> 

	<!-- ECONOMY -->
	<div class="economy-wrapper section-wrapper" id="economy">
	<div class="container flex flex-col">

		<h2>Economy</h2>

		<div class="economy-box flex justify-between">
			<div class="economy-left">
				<h3>DCT</h3>
			</div>
			<div class="economy-center">
				<img src="/assets/images/v2/images/rocket.png" />
			</div>
			<div class="economy-right flex flex-col justify-center">
				<h3>E-commerce</h3>
				<h3>Asset Reward</h3>
			</div>
		</div>

	</div>
	</div>

	<!-- JNX Roadmap -->
	<div class="roadmap-wrapper section-wrapper" id="roadmap">
		<div class="container flex flex-col">

			<h2>JNX Roadmap</h2>

			<div class="roadmap-card-box flex justify-center">
				<div class="roadmap-card roadmap-odd">
					<span class="rc-dot-outer">
						<span class="rc-dot-inner"></span>
					</span>
					<div class="rc-desc">
						<div class="rcd-img">
							<img src="/assets/images/v2/images/token.png" />
						</div>
						<h5>Q4 2022</h5>
						<div class="rcd-info">
							<h6>JNX Payment System</h6>
							<h6>Asset Reward Mechanism</h6>
							<h6>Testing Asset Reward (Testnet)</h6>
							<h6>Whitepaper</h6>
						</div>
					</div>
				</div>

				<div class="roadmap-divider"></div>

				<div class="roadmap-card roadmap-even">
					<span class="rc-dot-outer">
						<span class="rc-dot-inner"></span>
					</span>
					<div class="rc-desc">
						<div class="rcd-img">
							<img src="/assets/images/v2/images/token.png" />
						</div>
						<h5>Q1 2023</h5>
						<div class="rcd-info">
							<h6>Adding More Liquidity</h6>
							<h6>DEX Registration</h6>
							<h6>Strategic Partnerships</h6>
						</div>
					</div>
				</div>

				<div class="roadmap-divider roadmap-divider-even"></div>

				<div class="roadmap-card roadmap-odd">
					<span class="rc-dot-outer">
						<span class="rc-dot-inner"></span>
					</span>
					<div class="rc-desc">
						<div class="rcd-img">
							<img src="/assets/images/v2/images/token.png" />
						</div>
						<h5>Q2 2023</h5>
						<div class="rcd-info">
							<h6>Integration of E-commerce</h6>
							<h6>Launching of JNX and E-Commerce Program</h6>
						</div>
					</div>
				</div>

				<div class="roadmap-divider"></div>

				<div class="roadmap-card roadmap-even">
					<span class="rc-dot-outer">
						<span class="rc-dot-inner"></span>
					</span>
					<div class="rc-desc">
						<div class="rcd-img">
							<img src="/assets/images/v2/images/token.png" />
						</div>
						<h5>Q3 2023</h5>
						<div class="rcd-info">
							<h6>Launch Asset Reward</h6>
							<h6>CMC Registration</h6>
						</div>
					</div>
				</div>

				<div class="roadmap-divider roadmap-divider-even"></div>

				<div class="roadmap-card roadmap-odd">
					<span class="rc-dot-outer">
						<span class="rc-dot-inner"></span>
					</span>
					<div class="rc-desc">
						<div class="rcd-img">
							<img src="/assets/images/v2/images/token.png" />
						</div>
						<h5>Q4 2023</h5>
						<div class="rcd-info">
							<h6>Strategic Partnerships</h6>
							<h6>Games Integration</h6>
						</div>
					</div>
				</div>

				<div class="roadmap-divider"></div>

				<div class="roadmap-card roadmap-even">
					<span class="rc-dot-outer">
						<span class="rc-dot-inner"></span>
					</span>
					<div class="rc-desc">
						<div class="rcd-img">
							<img src="/assets/images/v2/images/token.png" />
						</div>
						<h5>Q1 2024</h5>
						<div class="rcd-info">
							<h6>Testing Game (Testnet)</h6>
							<h6>Integration of NFT</h6>
						</div>
					</div>
				</div>

				<div class="roadmap-divider roadmap-divider-even"></div>

				<div class="roadmap-card roadmap-odd">
					<span class="rc-dot-outer">
						<span class="rc-dot-inner"></span>
					</span>
					<div class="rc-desc">
						<div class="rcd-img">
							<img src="/assets/images/v2/images/token.png" />
						</div>
						<h5>Q2 2024</h5>
						<div class="rcd-info">
							<h6>Testing of NFT (Testnet)</h6>
							<h6>Strategic Partnerships</h6>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

	<!-- Tokenomics -->
	<div class="tokenomics-wrapper section-wrapper" id="tokenomics">
	<div class="container flex flex-col">
		
		<h2>Tokenomics</h2>

		<div class="t-stats flex justify-evenly">
			<h3><span>Total Supply:</span> 1,000,000,000</h3>
			<h3><span>Decimal:</span> 18</h3>
			<h3><span>Max Transaction Amount:</span> 1,000,000</h3>
		</div>

		<div class="t-img">
			<img src="/assets/images/v2/images/tokenomics.svg" />
		</div>

		<div class="t-labels-box flex">
			<div class="t-labels flex justify-center t-labels-1">
				<div class="t-label tl-1">
					<span></span>
					<span></span>
					<h4>Token Allocation for Tax</h4>
				</div>
				<div class="t-label tl-2">
					<span></span>
					<span></span>
					<h4>Marketing</h4>
				</div>
				<div class="t-label tl-3">
					<span></span>
					<span></span>
					<h4>Public sales</h4>
				</div>
			</div>

			<div class="t-labels flex justify-center t-labels-2">
				<div class="t-label tl-4">
					<span></span>
					<span></span>
					<h4>Development</h4>
				</div>
				<div class="t-label tl-5">
					<span></span>
					<span></span>
					<h4>Team</h4>
				</div>
				<div class="t-label tl-6">
					<span></span>
					<span></span>
					<h4>Private Sales</h4>
				</div>
				<div class="t-label tl-7">
					<span></span>
					<span></span>
					<h4>Advisors</h4>
				</div>
			</div>
		</div>

	</div>
	</div>

	<!-- Why Janex -->
	<div class="why-wrapper section-wrapper">
	<div class="container flex flex-col">

		<div class="why-card flex justify-between items-center why-card-odd">
			<div class="why-left">
				<h3>Why Should <span class="theme-color">I Buy JANEX?</span></h3>
				<p>Explore the reasons why Janex stands out in the world of digital assets. With its unique features, potential for growth, and a dedicated team behind it, Janex offers a compelling investment opportunity. Learn why you should buy Janex and be part of the future of finance.</p>
			</div>
			<div class="why-right">
				<img src="/assets/images/v2/images/rocket_laptop.png" />
			</div>
		</div>

		<div class="why-card flex justify-between items-center why-card-even">
			<div class="why-left">
				<h3>Why Should <span class="theme-color">I HODL </span><span class="font-less"> (HOLD) </span><span> JANEX?</span></h3>
				<p>HODL Janex for long-term gains, passive income, and strategic timing. Support the ecosystem and diversify your portfolio. Secure your financial future with Janex.</p>
			</div>
			<div class="why-right">
				<img src="/assets/images/v2/images/token_hand.png" />
			</div>
		</div>

	</div>
	</div>

	<!-- featured -->
	<div class="featured-wrapper section-wrapper">
	<div class="container flex flex-col">

		<h2>Fully featured to buy, trade and invest in <span class="theme-color">JANEX TOKEN</span></h2>

		<div class="f-card-wrapper flex justify-between">
			<div class="f-card">
				<img src="/assets/images/v2/images/send_receive.svg" />
				<h4>Send & receive anytime</h4>
				<p>Enjoy Quick And Secure Transactions With Janex. Send And Receive Anytime, Anywhere, Hassle-Free.</p>
			</div>
			<div class="f-card">
				<img src="/assets/images/v2/images/reports_analytics.svg" />
				<h4>Reports & analytics</h4>
				<p>Gain Valuable Insights With Our Reports And Analytics. Make Informed Decisions About Your Janex Holdings And Stay Ahead In The Crypto World.</p>
			</div>
			<div class="f-card">
				<img src="/assets/images/v2/images/safe_secure.svg" />
				<h4>Safe & secure</h4>
				<p>Your Security Is Our Priority. Trust In Janex To Keep Your Assets Safe As You Explore The World Of Digital Finance.</p>
			</div>
		</div>

	</div>
	</div>

	<!-- testimonial-slider -->
	<!-- <div class="testimonial-slider-wrapper section-wrapper none">
		<div class="container">

			<div class="block">

				<div class="testimonial-slider">
					<div>
						<img src="/assets/images/v2/images/token_hand.png" />
					</div>
					<div>
						<img src="/assets/images/v2/images/token_hand.png" />
					</div>
					<div>
						<img src="/assets/images/v2/images/token_hand.png" />
					</div>
					<div>
						<img src="/assets/images/v2/images/token_hand.png" />
					</div>
					<div>
						<img src="/assets/images/v2/images/token_hand.png" />
					</div>
				</div>

			</div>

		</div>
	</div> -->
</div>