import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connect-button-modal',
  templateUrl: './connect-button-modal.component.html',
  styleUrls: ['./connect-button-modal.component.scss']
})
export class ConnectButtonModalComponent implements OnInit {

  constructor(private activeModal : NgbActiveModal) { }

  ngOnInit(): void {
  }

  onInstallMetamaskExtension() {
    window.location.href = "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en";
  }

  onOpenInMetamaskApp() {
    window.open(`https://metamask.app.link/dapp/${environment.janexHomePage}`)
  }

  onCancel() {
    this.activeModal.close();
  }


}
