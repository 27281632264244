<div class="d-flex flex-column justify-content-center align-items-center">
  <h4 class="modal-title">Thank you for purchasing JNX!</h4>
  <div class="modal-body">
    <p>What would you like to do now now?</p>
  </div>
  <div class="d-flex flex-column justify-content-around align-items-center footer">
    <button type="button" class="highlighted-btn" (click)="purchaseTickets()">Purchase event tickets</button>
    <button type="button" class="highlighted-btn" (click)="purchasePackages()">Purchase packages</button>
    <button type="button" class="highlighted-btn" (click)="goHome()">Back to home</button>
    <a *ngIf="redirectionUrl" [href]="redirectionUrl" class="highlighted-btn" (click)="goHome()">{{redirectionUrlText}}</a>
  </div>
</div>