import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EncryptionService } from 'src/app/modules/encryption/services/encryption.service';

const encryptedParamsKey = "reqs_id"

const janexAmountParamKey = "janexAmount";
const redirectionUrlParamKey = "redirectionUrl";
const emailParamKey = "email";
const userWalletAddressParamKey = "userWalletAddress";
const targetWalletAddressParamKey = "targetWalletAddress";
const cryptoAmountParamKey = "userPa";
const gasFeeParamKey = "gasFee";

const decryptedParamKeys = [cryptoAmountParamKey, gasFeeParamKey, janexAmountParamKey, redirectionUrlParamKey, emailParamKey, userWalletAddressParamKey, targetWalletAddressParamKey]

@Injectable({
  providedIn: 'root'
})
export class ExternalTransferService {

  constructor(private encryption: EncryptionService) { }

  public getExternalTransferIfExistsInParams(params: Params): Receipt | undefined {
    try {
      let encrypted = this.validateEncryptedParams(params);
      return this.buildExternalTransferObjectFromEncryptedString(encrypted);
    } catch (err) {
      return undefined;
    }
  }

  private validateEncryptedParams(params: Params): string {
    let encrypted = params[encryptedParamsKey]
    if (encrypted) {
      encrypted = encrypted.split(' ').join('+')
      return encrypted
    }

    throw Error('Encrypted param not found.')
  }

  private buildExternalTransferObjectFromEncryptedString(encryptedString: string) : Receipt{
    let decrypted = this.encryption.blowfish.decrypt(encryptedString);
    let decryptedAsParams = new URLSearchParams(decrypted);
    this.validateDecryptedParams(decryptedAsParams);

    return this.constructExternalTransferReceiptFromValidatedParams(decryptedAsParams)
  }

  private validateDecryptedParams(decryptedParams: URLSearchParams){
    for(let key of decryptedParamKeys) {
      if(! decryptedParams.has(key)) {
        throw Error(`Required key '${key}' was not found in decrypted params`)
      }
    }
  }

  private constructExternalTransferReceiptFromValidatedParams(params : URLSearchParams) : Receipt {
    return {
      email: params.get(emailParamKey)!,
      redirectionUrl: params.get(redirectionUrlParamKey)!,
      janexAmount: parseFloat(params.get(janexAmountParamKey)!),
      userWalletAddress: `0x${params.get(userWalletAddressParamKey)!}`,
      targetWalletAddress: `0x${params.get(targetWalletAddressParamKey)!}`,
      cryptoAmount: parseFloat(params.get(cryptoAmountParamKey)!),
      gasFee: parseFloat(params.get(gasFeeParamKey)!)
    }
  }
}

export interface Receipt {
  janexAmount : number,
  userWalletAddress : string,
  email : string,
  redirectionUrl : string,
  targetWalletAddress : string,
  cryptoAmount : number,
  gasFee : number
}