import { Injectable } from '@angular/core';
import { CoingeckoApiService } from 'src/app/data/api/coingecko/coingecko-api.service';
import { CoingeckoCurrencyId } from 'src/app/data/api/coingecko/coingecko-models';
import { MoralisApiService } from 'src/app/data/api/moralis/moralis-api.service';
import { PancakeswapApiService } from 'src/app/data/api/pancakeswap/pancakeswap-api.service';
import { AppCrypto } from '../app-cryptos';

@Injectable({
  providedIn: 'root'
})
export class CryptoValueFetcherService {

  constructor(private moralisApi: MoralisApiService, private coingecko: CoingeckoApiService) { }

  public async getCurrencyValueInUsd(currency: AppCrypto): Promise<number | undefined> {
    try {
      if (currency === 'JNX') {
        return await this.getJanexValue();
      } else {
        return await this.getOtherAppCurrencyValue(currency)
      }
    } catch (e) {
      return undefined
    }
  }

  private async getJanexValue(): Promise<number> {
    let response: any = await this.moralisApi.getBinanceChainTokenValueInUsd('0xd3C117bD35025786714B56F44C93AD6DeB68B4D1');
    let value = response.usdPrice;
    return value;
  }

  private async getOtherAppCurrencyValue(currency: AppCrypto): Promise<number> {
    let id = this.mapAppCryptoToCoingeckoCurrencyId(currency)
    let response = await this.coingecko.getCurrencyPriceInUsd(id);
    let body = Object.values(response)[0]
    let value = Object.values(body)[0];
    return value as number
  }

  private mapAppCryptoToCoingeckoCurrencyId(crypto: AppCrypto): CoingeckoCurrencyId {
    switch (crypto) {
      case 'BNB':
        return 'binancecoin';
      case 'BTC':
        return 'bitcoin';
      case 'BUSD':
        return 'binance-usd';
      case 'USDT':
        return 'tether'
    }

    throw Error(`${crypto} is not an acceptable parameter.`)
  }
}
