<div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="modal-title">Login</h4>
    <div class="modal-body">
        <!-- <h6 class="btc-warning-message">Please be aware that transactions require a number of confirmations. To avoid delay, please have enough currency in your wallet before creating the invoice. Once these confirmations are done, you will instantly receive your required JNX.</h6> -->
        <form (keyup.enter)="onSignIn()" #loginForm="ngForm" class="modal-body-items">
            <h6 class="enter-email-label">Please enter your DCT credentials below to proceed:</h6>

            <div class="email-field-container field">
                <input type="text" class="username-field-input" [(ngModel)]="username" name="username"
                    placeholder="username" required>
            </div>

            <div class="email-field-container field">
                <input type="password" class="username-field-input" [(ngModel)]="password" name="password"
                    placeholder="password" required>
            </div>

            <button type="button" class="highlighted-btn login-btn" (click)="onSignIn()" [disabled]="loggingIn"
                [ngClass]="{'disabled': loginForm.invalid}">
                <span *ngIf="loggingIn">
                    <div class="loader"></div>
                </span>
                <span *ngIf="!loggingIn">Login</span>
            </button>
        </form>

    </div>
    <!-- <div class="d-flex justify-content-around align-items-center footer">
        <button type="button" class="highlighted-btn" (click)="cancel()">Cancel</button>
    </div> -->
</div>