import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMiddleEllipsis]'
})
export class MiddleEllipsisDirective implements AfterViewInit {
  @Input('factor') factor = 9;

  @Input('charactersAtStart') charactersAtStart = 4

  @Input('charactersAtEnd') charactersAtEnd = 3;

  @Input('fallbackMaxWidth') fallbackMaxWidth = 200;

  constructor(private el: ElementRef) {
    el.nativeElement.textOverflow = 'trim';
    el.nativeElement.overflow = 'trim'
  }

  get width() {
    return this.el.nativeElement.maxWidth ?? this.fallbackMaxWidth
  }

  ngAfterViewInit(): void {
    this.applyEllipsisIfNecessary()
  }

  @HostListener('window:resize') onResize() {
    this.applyEllipsisIfNecessary()
  }

  private applyEllipsisIfNecessary() {
    let elementText: string = this.el.nativeElement.innerText;
    let elementWidth: number = this.width;
    let approximateTextWidth: number = this.factor * elementText.length;
    if (approximateTextWidth > elementWidth) {
      let ellipsisString = this.applyEllipsisToText(elementText, elementWidth);
      this.el.nativeElement.innerText = ellipsisString;
    }
  }

  private applyEllipsisToText(elementText: string, elementWidth: number): string {
    let startCharacters = elementText.substring(0, this.charactersAtStart);
    let endCharacters = elementText.substring(elementText.length - this.charactersAtEnd);
    let approximateStartSize = this.charactersAtStart * this.factor;
    let approximateEndSize = this.charactersAtEnd * this.factor;
    let leftoverSize = elementWidth - (approximateEndSize + approximateStartSize);
    let numberOfDots = leftoverSize / this.factor;
    if (numberOfDots < 0) numberOfDots = 0;
    let finalString = startCharacters + '.'.repeat(Math.floor(numberOfDots)) + endCharacters
    return finalString
  }
}
