import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// export const lowMinValue = 15;
// export const lowMaxValue = 90;
// export const highMinValue = 998;
// export const highMaxValue = 999999;

export const minValueUsd = environment.minPurchaseValueInUsd;
export const maxValueUsd = environment.maxPurchaseValueInUsd;

@Injectable({
  providedIn: 'root'
})
export class CurrencyValuesValidatorService {

  constructor() { }

  validateJanex(janexValue : number, janexValueInUsd : number) : string | undefined{
    if(this.janexValueBelowMinimum(janexValue, janexValueInUsd)) {
      return `Minimum value is ${minValueUsd} USD (~${Math.ceil(minValueUsd / janexValueInUsd)} JNX).`
    } 
    if(this.janexValueAboveMaximum(janexValue, janexValueInUsd)) {
      return `Maximum value is ${maxValueUsd} USD (~${Math.ceil(maxValueUsd / janexValueInUsd)} JNX).`
    }

    return undefined;
  }

  private janexValueBelowMinimum(janexValue : number, janexValueInUsd : number): boolean {
    return (janexValue * janexValueInUsd) < minValueUsd;
  }

  private janexValueAboveMaximum(janexValue : number, janexValueInUsd : number): boolean {
    return (janexValue * janexValueInUsd) > maxValueUsd;
  }

  // private janexValueInMidRange(value : number) : boolean {
  //   return value > lowMaxValue && value < highMinValue
  // }
}
