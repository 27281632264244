<section class="why-janex-section blue-waves-background">
    <div class="why-janex-items d-flex flex-column justify-content-center align-items-center">
        <h1 class="why-janex-title title">
            Why Janex
        </h1>

        <img class="janex-icon" src="/assets/images/background-images/token.png" alt="janex icon">

        <h5 class="body-text">
            Janex will be designed from the ground up to both utilize and work on-top of the Binance Smart Chain
            Blockchain. Our system leverages the blockchain in order for transaction ledgers and other components to
            remain recorded and immutable within the blockchain whereas specific code resides outside of it. Such
            structure permits Janex’s users and merchants and their generated data to be easily put through data
            segmentation and analysis which will further on de-facto indicate metrics such as useful business health
            indicators.
        </h5>
        <h5 class="body-text">
            We have chosen Binance Smart Chain for its functionality and security capabilities. The expected scale of
            Janex platform, according to the number of our potential users, represents tens of thousands of deals and
            offers, hundreds of thousands of businesses, and millions of consumers. This level of load currently can be
            handled by Binance Smart Chain.
        </h5>
        <h5 class="body-text">
            Janex will function as a trusted community marketplace for people to list, discover, and book unique
            accommodation properties around the world as well as shop, learn, and access unique opportunities in
            e-commerce and education. Our aim is to give crypto holders direct access to real estate, travel,
            e-commerce, and education globally.

        </h5>
    </div>
</section>