import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { DctLoginComponent } from '../../../modals/dct-login/dct-login.component';
// import { ToastrService } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-single-report',
  templateUrl: './single-report.component.html',
  styleUrls: ['./single-report.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatTableModule, CommonModule, MatPaginatorModule],
})
export class SingleReportComponent {
  txnNumber: string | null = null;
  element: any;
  report: TReport = {
    amount: '',
    created_at: '',
    created_by: 0,
    days: 0,
    reward_percentage: '',
    status: 0,
    status_name: '',
    txn_hash: '',
    txn_no: '',
    date: '',
    end_date: '',
    reward_amount: '',
    total_amount: '',
  };
  // dataSource: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private route: ActivatedRoute,
    private janexApi: JanexApiService,
    private modalService: NgbModal,
    // private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.txnNumber = this.route.snapshot.paramMap.get('txnNumber');
    this.getAssetDetails(this.txnNumber!);
    this.element = this.route;
  }

  // {
  //   amount: 100,
  //   claim_reward: false,
  //   date: '23-08-2023',
  //   date_time: '23-08-2023 12:50:39',
  //   days: 14,
  //   email: 'mohd191141@yopmail.com',
  //   end_date: '06-09-2023',
  //   full_name: 'Mohd Zuhaib',
  //   id: 61,
  //   reward_amount: 3,
  //   reward_percentage: 3,
  //   status: 1,
  //   status_name: 'Active',
  //   total_amount: 103,
  //   txn_hash:
  //     '0x0f25b9df66f639dada160d7285f702dd8dcbbb6c39b85d84e3b0bfb1263073d4',
  //   txn_no: 'VR7671781127',
  //   user_id: 50022,
  //   username: 'zuhaib19',
  // },

  displayedColumns: string[] = [
    'date',
    'reward_percentage',
    'reward_amount',
    'cumulative_amount',
  ];

  async getAssetDetails(txnNumber: string) {
    try {
      const response = await this.janexApi.assetRewardSingleReport(txnNumber);

      if (response.data?.status == 12) {
        this.router.navigate(['/asset-reward/report']);
        return;
      }

      this.report = response.data;

      // Initialize an empty array to store rows
      const rows = [];

      // Parse the date string to a valid Date object using the existing dateParts
      const dateParts = this.report.date.split('-');
      const year = parseInt(dateParts[2]);
      const month = parseInt(dateParts[1]) - 1; // Month is zero-based in JavaScript
      const day = parseInt(dateParts[0]) + 1;

      const totalRewardAmount = parseFloat(this.report.reward_amount);
      const days = this.report.days;

      for (let i = 0; i < days; i++) {
        const currentDate = new Date(year, month, day + i);

        // Calculate the daily reward amount incrementally
        const dailyRewardAmount = (totalRewardAmount / days) * (i + 1);

        // Calculate the reward percentage based on the daily reward amount
        const rewardPercentage = (dailyRewardAmount / totalRewardAmount) * 100;

        const iterationDate = new Date(year, month, day + i);

        // Check if the calculated date is in the future

        // if (currentDate <= new Date()) {
        if (iterationDate >= currentDate && currentDate <= new Date()) {
          rows.push({
            total_amount: this.report.total_amount,
            date: currentDate.toLocaleDateString('en-GB'),
            reward_percentage: rewardPercentage
              .toFixed(2)
              ?.replace(/\.?0*$/, ''), // Adjust decimal places as needed
            reward_amount: dailyRewardAmount.toFixed(2)?.replace(/\.?0*$/, ''),
            cumulative_amount: (
              parseFloat(this.report.amount) + dailyRewardAmount
            )
              .toFixed(2)
              ?.replace(/\.?0*$/, ''),
          });
        }
      }

      // Initialize dataSource with the generated rows
      this.dataSource = new MatTableDataSource(rows);
      this.dataSource.paginator = this.paginator;
    } catch (error: any) {
      if (error.status === 401) {
        this.modalService.open(DctLoginComponent);
      } else {
        // this.toastr.error(error?.error?.message);
        this.router.navigate(['/asset-reward/report']);
      }
    }
  }

  handleStatus = (status: any) => {
    switch (status) {
      case 1:
        return 'status-active status-pill';
      case 2:
        return 'status-pending status-pill';
      case 10:
        return 'status-review status-pill';
      case 12:
        return 'status-expired status-pill';
      case 13:
        return 'status-settled status-pill';
      default:
        return 'status-pill';
    }
  };

  getStatusName(status: string) {
    if (status.toLowerCase() === 'active') {
      return 'Processed';
    } else {
      return status;
    }
  }
}

type TReport = {
  amount: string;
  created_at: string;
  created_by: number;
  days: number;
  reward_percentage: string;
  status: number;
  status_name: string;
  txn_hash: string;
  txn_no: string;
  date: string;
  end_date: string;
  reward_amount: string;
  total_amount: string;
};
