import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BscApiService } from './api/bsc/bsc-api.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { EncryptionModule } from '../modules/encryption/encryption.module';
import { CoingeckoApiService } from './api/coingecko/coingecko-api.service';
import { PancakeswapApiService } from './api/pancakeswap/pancakeswap-api.service';
import { JanexApiService } from './api/janex/janex-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    EncryptionModule
  ],
  providers: [BscApiService, CoingeckoApiService, PancakeswapApiService, JanexApiService]
})
export class DataModule { }
