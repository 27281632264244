import { BehaviorSubject, Observable, Observer, Subscribable, Unsubscribable } from "rxjs";

export class PeriodicBalanceChecker implements Subscribable<number>{
    isPaused = false;

    private _balance = new BehaviorSubject<number>(-1);

    private interval : NodeJS.Timeout;

    public get balance() {return this._balance.value}

    constructor(checkEveryMilliseconds : number, balanceCheckCallback : () => Promise<number>) {
        this.interval = this.createInterval(checkEveryMilliseconds, balanceCheckCallback)
    }

    private createInterval(checkEveryMilliseconds : number, balanceCheckCallback : () => Promise<number>) : NodeJS.Timeout{
        return setInterval(async () => {
            if(! this.isPaused) {
                let newBalance = await balanceCheckCallback()
                this._balance.next(newBalance)
            }
        }, checkEveryMilliseconds);
    }

    asObservable() : Observable<number> {
        return this._balance.asObservable()
    }

    cancel() {
        clearInterval(this.interval);
        this._balance.complete();
    }

    pause() {
        this.isPaused = true;
    }

    resume() {
        this.isPaused = false;
    }

    subscribe(observer: Partial<Observer<number>>): Unsubscribable {
        return this._balance.subscribe(observer);
    }
}