<!------------------->
<!-- INTRO SECTION -->
<!------------------->
<section class="intro-section">

  <div class="container-fluid">
    <div class="d-flex flex-column flex-md-row justify-content-around align-items-center">

      <section class="reward-section">
        <app-wallet-balance [wallet]="wallet" [username]="username"></app-wallet-balance>

        <div class="container-fluid">

          <div class="form-wrapper">
            <h2>Asset Reward</h2>

            <form *ngIf="!insufficientBalance" (ngSubmit)="onSubmit()" [formGroup]="rewardForm" novalidate>

              <mat-form-field appearance="outline">
                <mat-label>Amount in JNX</mat-label>
                <input matInput type="number" formControlName="amount" (click)="handleAmountClick()">
                <mat-error *ngIf="rewardForm.get('amount')?.hasError('required')">Amount is required</mat-error>
                <mat-error *ngIf="rewardForm.get('amount')?.hasError('min')">Amount must be at least
                  {{ rewardForm.get('amount')?.getError('min').min }}</mat-error>
                <mat-error *ngIf="rewardForm.get('amount')?.hasError('max')">Amount must be less than
                  {{ rewardForm.get('amount')?.getError('max').max }}</mat-error>
              </mat-form-field>

              <div class="row text-center"  style="padding: 0 12px; margin-bottom: 25px;" *ngIf="enableAmount">
                <div class="col pill-container spaced-div" (click)="updateAmount(25)">
                  <span class="custom-pill">25%</span>
                </div>
                <div class="col pill-container spaced-div" (click)="updateAmount(50)">
                  <span class="custom-pill">50%</span>
                </div>
                <div class="col pill-container spaced-div" (click)="updateAmount(75)">
                  <span class="custom-pill">75%</span>
                </div>
                <div class="col pill-container spaced-div" (click)="updateAmount(100)">
                  <span class="custom-pill">100%</span>
                </div>
              </div>

              <mat-form-field appearance="outline">
                <mat-label>Time Period</mat-label>
                <mat-select formControlName="time">
                  <mat-option *ngFor="let duration of durationWalletRes" [value]="duration.id">
                    {{ duration.days }} Days
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="rewardForm.get('time')?.hasError('required')">Time Period is required</mat-error>
              </mat-form-field>

              <div *ngIf="selectedTime" class="info d-flex justify-content-center">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="info"></mat-icon>
                <label>You will receive {{selectedTime.rate}}% for {{selectedTime.days}} days</label>
              </div>

              <mat-form-field appearance="outline" class="form-fields" style="border-radius: 10px;">
                <mat-label>Transaction Password</mat-label>
                <input matInput type="password" formControlName="password">
                <mat-error *ngIf="rewardForm.get('password')?.hasError('required')">Transaction Password is required
                </mat-error>
              </mat-form-field>

              <section class="form-fields">
                <mat-checkbox class="example-margin" color="primary" formControlName="consent">I agree to all the terms
                  and conditions. *</mat-checkbox>
                <mat-error
                  *ngIf="rewardForm.get('consent')?.hasError('required') && rewardForm.get('consent')?.touched">You must
                  agree to the terms and conditions</mat-error>
              </section>

              <!-- <div class="button-wrapper">
                <button mat-raised-button color="primary" [disabled]="!rewardForm.valid || loading">Submit</button>
              </div> -->
              <div class="d-flex justify-content-center btn-container">
                <button [ngClass]="setButtonCSS()" class="button-wrapper" color="primary" [disabled]="!rewardForm.valid || loading">
                  <span *ngIf="loading">
                    <div class="loader"></div>
                  </span>
                  <span *ngIf="!loading" class="text-white">Submit</span>
                </button>
              </div>
              <!-- <button mat-raised-button color="primary" [disabled]="!rewardForm.valid || loading">
                <span *ngIf="loading">
                  <div class="loader"></div>
                </span>
                <span *ngIf="!loading">Submit</span>
              </button> -->

            </form>

            <h6 *ngIf="insufficientBalance">
              Your wallet has insufficient balance. Minimum required balance for this feature is {{ minimumAmount }}
              <br />
              <br />
              Add more Janex to your wallet and refresh this page.
            </h6>

          </div>

        </div>

      </section>

      <div class="intro-shape-container image-animate" (document:mousemove)="onMouseMove($event)">
        <img class="blockchain-polyhedron" src="/assets/images/background-images/blockchain-polyhedron.png">
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
      </div>

    </div>
  </div>
</section>