import {Component, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule, NgFor, NgIf} from '@angular/common';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';
import { ToastrService } from 'ngx-toastr';
import { DctLoginComponent } from '../../modals/dct-login/dct-login.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import Swal from 'sweetalert2'
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NavigationExtras, Router } from '@angular/router';

/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  standalone: true,
  imports: [
    MatTableModule,
    NgFor,
    MatButtonModule,
    NgIf,
    MatIconModule,
    MatPaginatorModule,
    CommonModule,
    TranslateModule,
  ],
})
export class ReportComponent {
  dataSource: MatTableDataSource<PeriodicElement> =
    new MatTableDataSource<PeriodicElement>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  columnsToDisplay = [
    // 'sno',
    'date',
    'end_date',
    'txn_no',
    'days',
    'amount',
    'reward_percentage',
    // 'reward_amount',
    // 'total_amount',
    'status',
    'claim_reward',
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null | undefined;
  loggingIn: boolean = false;

  currentDay: number = 1;
  cancelAssetTransactionCharge: string = "";

  constructor(
    private modalService: NgbModal,
    private janexApi: JanexApiService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  navigateToDetail(element: any) {
    const url = `/asset-reward/report/single/${element.txn_no}`;
    this.router.navigateByUrl(url, {
      state: { element },
    });
  }

  handleStatus = (status: any) => {
    switch (status) {
      case 1:
        return 'status-active status-pill';
      case 2:
        return 'status-pending status-pill';
      case 10:
        return 'status-review status-pill';
      case 12:
        return 'status-expired status-pill';
      case 13:
        return 'status-settled status-pill';
      case 16:
        return 'status-confirmed status-pill';
      case 11:
        return 'status-cancelled status-pill';
      default:
        return 'status-pill';
    }
  };

  // getAmountPlusProfit(currentDay: number, element: PeriodicElement): string {
  //   const dailyProfit = element.reward_amount / element.days;
  //   console.log('dailyProfit',dailyProfit)
  //   console.log('element.reward_amount / element.days',element.reward_amount , " " , element.days)
  //   const totalProfit = dailyProfit * currentDay;
  //   const totalAmountWithProfit = parseFloat(element.amount) + totalProfit;
  //   return totalAmountWithProfit.toFixed(3);
  // }

  async loadData() {
    this.getCancelAssetTransactionCharge();

    const auth_token = this.janexApi.getCookieValue('auth_token');

    if (!auth_token) {
      this.modalService.open(DctLoginComponent);
    } else {
      try {
        const response = await this.janexApi.assetRewardReport();
        this.dataSource = new MatTableDataSource(response.data);

        this.dataSource.paginator = this.paginator;

        this.dataSource.data.forEach((element) => {
          const parts = element.date_time.split(' ');
          const dateParts = parts[0].split('-');
          const timeParts = parts[1].split(':');

          // Assuming the date format is DD-MM-YYYY
          const year = parseInt(dateParts[2]);
          const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed
          const day = parseInt(dateParts[0]);

          const hours = parseInt(timeParts[0]);
          const minutes = parseInt(timeParts[1]);
          const seconds = parseInt(timeParts[2]);

          const date = new Date(year, month, day, hours, minutes, seconds);

          // element.dailyReward = element.total_amount / element.days;
          // const endTime = date.getTime() + element.days * 24 * 60 * 60 * 1000;
          // element.endTime = endTime;
          element.dailyReward = element.reward_amount / element.days;
          const endTime = date.getTime() + element.days * 24 * 60 * 60 * 1000;
          element.endTime = endTime;
          element.intervalId = null;

          // Calculate progress for each reward
          const currentTime = new Date().getTime();
          const timeLeft = Math.max(endTime - currentTime, 0);
          element.currentDay =
            element.days - Math.ceil(timeLeft / (24 * 60 * 60 * 1000));
        });

        // const currentTime = new Date().getTime();
        // const earliestEndTime = Math.min(
        //   ...this.dataSource.data.map((element) => element.endTime)
        // );

        // if (currentTime < earliestEndTime) {
        //   setInterval(() => {
        //     const timeLeft = earliestEndTime - currentTime;
        //     this.currentDay =
        //       this.dataSource.data[0].days -
        //       Math.ceil(timeLeft / (24 * 60 * 60 * 1000));
        //   }, 1000); // This interval will continuously update the progress
        // }
        this.dataSource.data.forEach((element) => {
          if (element.currentDay <= element.days) {
            element.intervalId = setInterval(() => {
              if (element.currentDay <= element.days) {
                element.currentDay += 1;
              } else {
                clearInterval(element.intervalId);
              }
            }, 24 * 60 * 60 * 1000); // This interval will update the progress every 24 hours
          }
        });
      } catch (error: any) {
        if (error.status === 401) {
          this.modalService.open(DctLoginComponent);
        } else {
      
          this.toastr.error(error?.error?.data?.error);
        }
      }
    }
  }

  async getCancelAssetTransactionCharge() {
    try {
      const response = await this.janexApi.cancelAssetTransactionCharge();
      this.cancelAssetTransactionCharge = response.data;
    } catch (error: any) {
      if (error.status === 401) {
        this.modalService.open(DctLoginComponent);
      } else {
        alert('DctLoginComponent');
        this.toastr.error(error?.error?.data?.error);
      }
    }
  }

  async ngOnInit() {
    this.loadData();
  }

  // parseAmount(amount: string): number {
  //   return parseFloat(amount);
  // }

  calculateProgressValue(element: any): string {
    const parsedAmount = parseFloat(element.amount);
    const progressValue =
      parsedAmount + element.dailyReward * element.currentDay;
    return progressValue.toFixed(3);
  }

  getColumnHeaderContent(column: string): string {
    if (column === 'reward_percentage') {
      return 'JNX reward (%)';
    } else if (column === 'amount') {
      return 'amount (JNX)';
    } else if (column === 'reward_amount') {
      return 'reward (JNX)';
    } else if (column === 'total_amount') {
      return 'total (JNX)';
    } else if (column === 'claim_reward') {
      return 'action';
    } else if (column === 'txn_no') {
      return 'txn no';
    } else if (column === 'end_date') {
      return 'end date';
    } else if (column === 'date') {
      return 'start date';
    } else {
      return column;
    }
  }

  async onClaimButton($event: any, element: any) {
    $event.stopPropagation();

    Swal.fire({
      html: `Are you sure you want to claim your reward of <br/><b>${element.total_amount} JNX</b>?
              <br/><br/>
              Note that a transaction fee of 0.5% will apply.`,
      icon: 'info',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Claim',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Loading...',
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.claim(element.id);
      }
    });
  }

  async onCancelAssetButton($event: any, element: any) {
    $event.stopPropagation();

    Swal.fire({
      html: `Are you sure you want to cancel your asset of <br/><b>${element.amount}</b> JNX having Txn No: <b>${element.txn_no}</b>?
              <br/><br/>
              <b>Note that a transaction / refund fee of ${this.cancelAssetTransactionCharge}% will apply.</b>`,
      icon: 'info',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Yes, cancel',
      cancelButtonText: 'No',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Loading...',
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });

        this.cancelAsset(element.id);
      }
    });
  }

  async onRevestButton($event: any, element: any) {
    
    const data = {
      previous_txn_no: element.txn_no,
      total_amount: element.total_amount

    };

    const navigationExtras: NavigationExtras = {
      state: {
        data,
      },
    };

    $event.stopPropagation();
    this.router.navigate(['/asset-revest'], navigationExtras);
  }

  async claim(id: number) {
    try {
      const response = await this.janexApi.claim(id);

      Swal.fire({
        title: 'Claim processed successfully',
        allowEscapeKey: false,
      }).then((result) => {
        window.location.reload();
      });
    } catch (error: any) {
      Swal.close();
     
      if (error.status === 401) {
        this.modalService.open(DctLoginComponent);
      } else {
        this.toastr.error(error?.error?.message);
      }
    }
  }

  async cancelAsset(id: number) {
    try {
      const response = await this.janexApi.cancelAsset(id);

      Swal.fire({
        title: 'Asset cancelled successfully',
        allowEscapeKey: false,
      }).then((result) => {
        window.location.reload();
      });
    } catch (error: any) {
      Swal.close();
      console.log(error.error);
      if (error.status === 401) {
        this.modalService.open(DctLoginComponent);
      } else {
        // alert('DctLoginComponent 2')
        this.toastr.error(error?.error?.message);
      }
    }
  }

  getStatusName(status: string) {
    if (status.toLowerCase() === 'active') {
      return 'Processed';
    } else {
      return status;
    }
  }

  getStatusDescription(status: number) {
    switch (status) {
      case 1:
        return 'Asset is processed and locked for the chosen days';
      case 2:
        return 'Asset is pending and will be reviewed soon';
      case 10:
        return 'Asset process has started and will be processed soon';
      case 12:
        return 'Asset has expired because of insufficient funds of some other issue';
      case 13:
        return 'Reward amount has been processed';
      case 16:
        return 'Claim has been confirmed. You will receive the amount as soon as approved by the admin';
      case 11:
        return 'Asset has been cancelled before reaching maturity';
      default:
        return '';
    }
  }
}

export interface PeriodicElement {
  // sno: number;
  date: string;
  date_time: string;
  end_date: string;
  txn_no: string;
  days: number;
  amount: string;
  reward_percentage: string;
  reward_amount: number;
  // total_amount: number;
  status: number;
  claim_reward: boolean;
  dailyReward: number;
  endTime: number;
  currentDay: number;
  intervalId: any;
}