import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Cookies from 'js-cookie';
import { ToastrService } from 'ngx-toastr';
import { JanexApiService } from 'src/app/data/api/janex/janex-api.service';

type TLoginResponse = {
  email: string;
  status: string;
  token: string;
  wallet: string;
}

@Component({
  selector: 'app-dct-login',
  templateUrl: './dct-login.component.html',
  styleUrls: ['./dct-login.component.scss']
})
export class DctLoginComponent {
  @Input('password') password: string = '';

  @Input('username') username: string = '';

  showPassword = false;

  loggingIn: boolean = false;

  constructor(private toastr : ToastrService, private janexApi: JanexApiService, private modal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  onSignIn() {
    if (this.validateInputs()) {
      this.signIn()
    }
  }

  onDeleteToken() {
    this.deleteAuthToken();
  }

  private validateInputs() {
    let valid = this.password.length > 0 &&  this.username.length > 0;
    if(!valid) {
      this.toastr.warning('Email and password fields should not be empty')
    }
    return valid;
  }

  private async signIn() {
    this.loggingIn = true;
    try {
      let response = await this.janexApi.signInToDct(this.username, this.password);

      if(response.status === "success") {
        this.setAuthToken(response);
        this.modal.close(response);
        this.toastr.success("Login success");
        window.location.reload();
      } else if (response.status === "error") {
        this.toastr.warning(response.msg)
      }
      
    } catch (err) {
      this.toastr.error('Connection error. Please make sure you have an active internet connection and try again.')
    }

    this.loggingIn = false;
  }

  cancel() {
    this.modal.close()
  }

  private deleteAuthToken() {
    Cookies.remove("auth_token");
    // document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  private setAuthToken(response: TLoginResponse) {
    Cookies.set("auth_token", response.token);
    // document.cookie = `auth_token=${response.token}`;
  }
}
