<!-- <section class="section">

  <div class="container-fluid">
    <div class="d-flex flex-column flex-md-column justify-content-around align-items-baseline">

        <a 
            href="/asset-reward/"
            mat-raised-button 
            color="primary"
        >
            Back to Asset Reward
        </a>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef> S.No. </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{ i + 1 }} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>

        <ng-container matColumnDef="txn_no">
          <th mat-header-cell *matHeaderCellDef> Transaction Number </th>
          <td mat-cell *matCellDef="let element"> {{element.txn_no}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
        </ng-container>

        <ng-container matColumnDef="reward_percentage">
          <th mat-header-cell *matHeaderCellDef> Reward Percentage </th>
          <td mat-cell *matCellDef="let element"> {{element.reward_percentage}} </td>
        </ng-container>

        <ng-container matColumnDef="reward_amount">
          <th mat-header-cell *matHeaderCellDef> Reward Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.reward_amount}} </td>
        </ng-container>

        <ng-container matColumnDef="total_amount">
          <th mat-header-cell *matHeaderCellDef> Total Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.total_amount}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element" [ngClass]="handleStatus(element.status)" > {{element.status_name}} </td>
        </ng-container>

        <ng-container matColumnDef="claim_reward">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="primary" *ngIf="element.claim_reward">Claim Reward</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
              </button>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>
</section> -->

<section class="section">

  <div class="d-flex align-items-center justify-content-center">
    <h3 class="purchase-section-title">Asset Reward Report</h3>
  </div>

  <div class="container-fluid">
    <div class="d-flex flex-column flex-md-column justify-content-around align-items-baseline">

      <div class="d-flex justify-content-between" style="width: 100%;">
        <a href="/asset-reward/" mat-raised-button color="primary" class="cta-btn">
          Back to Asset Reward
        </a>
      
        <a href="/asset-reward/report/combined" mat-raised-button color="primary" class="cta-btn">
          Combined Report
        </a>
      </div>

      <div class="d-flex flex-column table-wrapper">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="">

          <ng-container class="table-container" matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay;">
            <th mat-header-cell *matHeaderCellDef> {{ getColumnHeaderContent(column) }} </th>
            <td mat-cell *matCellDef="let element; let i = index;">
              <!-- <ng-container *ngIf="column === 'sno'; else claimRewardContent">
                <div> {{ i + 1 }} </div>
              </ng-container> -->
              <!-- <ng-template #claimRewardContent> -->
                <ng-container *ngIf="column === 'claim_reward'; else statusContent">
                  <button 
                    class="claim_btn"
                    mat-raised-button
                    *ngIf="element.claim_reward" 
                    (click)="onClaimButton($event, element)" 
                    [disabled]="loggingIn"
                  >
                    <span *ngIf="loggingIn">
                      <div class="loader"></div>
                    </span>
                    <span *ngIf="!loggingIn" class="text-light">Claim Reward</span>
                  </button>
                </ng-container>

                <ng-container *ngIf="column === 'claim_reward';">
                  <button 
                    class="revest_btn"
                    mat-raised-button
                    *ngIf="element.claim_reward" 
                    (click)="onRevestButton($event, element)" 
                    [disabled]="loggingIn"
                  >
                    <span *ngIf="loggingIn">
                      <div class="loader"></div>
                    </span>
                    <span *ngIf="!loggingIn" class="text-light">Revest</span>
                  </button>
                </ng-container>

                <!-- Cancel Asset Button -->
                <ng-container *ngIf="column === 'claim_reward';">
                  <button 
                    class="revest_btn"
                    mat-raised-button
                    *ngIf="!element.claim_reward && element.status == 1" 
                    (click)="onCancelAssetButton($event, element)" 
                    [disabled]="loggingIn"
                  >
                    <span *ngIf="loggingIn">
                      <div class="loader"></div>
                    </span>
                    <span *ngIf="!loggingIn" class="text-light">Cancel Asset</span>
                  </button>
                </ng-container>

              <!-- </ng-template> -->
              <ng-template #statusContent>
                <ng-container *ngIf="column === 'status'; else otherContent">
                  <div [ngClass]="handleStatus(element.status)" > 
                    <span></span>
                    {{ getStatusName(element.status_name) }}
                    <!-- {{element.status_name}}  -->
                    <div class="d-flex info-wrap">
                      <mat-icon aria-hidden="false" aria-label="info" fontIcon="info"></mat-icon>
                      <div class="info-description">
                        {{ getStatusDescription(element.status) }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template #otherContent>
                {{element[column]}}
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button aria-label="expand row"
                (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length" (click)="navigateToDetail(element)">
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="progress mb-3 bg-custom-color"
                  *ngIf="(element.status === 1 || element.status === 13) && (element.currentDay <= element.days)">
                  <div class="progress-bar progress-bar-striped progress-bar-animated bg-gradient" role="progressbar"
                    [style.width]="(element.currentDay / element.days) * 100 + '%'" [attr.aria-valuenow]="element.currentDay"
                    aria-valuemin="0" [attr.aria-valuemax]="element.days">
                    <span class="progress-label" style="font-weight: 700; cursor: pointer;">
                      <!-- {{ (parseAmount(element.amount) + (element.dailyReward * element.currentDay)) | number: '1.0-3' }} JNX  -->
                      {{ calculateProgressValue(element) }} JNX
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

        <div class="d-flex paginator-wrapper">
          <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>

    </div>
  </div>
</section>