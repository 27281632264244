import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PancakeswapApiService {

  constructor(private http : HttpClient) {}

  public async getBinanceChainTokenValueInUsd(address : string) {
    let url = `${environment.pancakeswapBaseUrl}/tokens/${address}`
    let obs = this.http.get(url);
    return lastValueFrom(obs)
  }
}
