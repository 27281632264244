import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-enter-address-modal',
  templateUrl: './enter-address-modal.component.html',
  styleUrls: ['./enter-address-modal.component.scss']
})
export class EnterAddressModalComponent implements OnInit {
  walletAddress : string = "";

  walletAddressInvalid? : boolean;

  constructor(private cdr: Router, private activeModal: NgbActiveModal) { }

  confirm() {
    this.activeModal.close(this.walletAddress);
  }

  cancel() {
    this.activeModal.close()
  }

  validateWalletAddress() {
    if(this.walletAddress.startsWith("0x") && this.walletAddress.length > 3) {
      this.walletAddressInvalid = false
    } else {
      this.walletAddressInvalid = true;
    }
    
  }

  ngOnInit(): void {
  }

}
