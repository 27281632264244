<div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="modal-title">No Wallet Detected</h4>
    <div class="modal-body">
      <p style="color: silver;">In order to receive Janex, you need to have your Janex wallet connected with our website. We couldn't detect a wallet instance in the browser. If you're on Desktop you can install Metamask extension. If you're on your smart phone, you can open this page using your Metamask app.</p>
      <p>Please select an option below to proceed.</p>
    </div>
    <div class="d-flex justify-content-around flex-column align-items-center footer">
      <button type="button" class="highlighted-btn" (click)="onOpenInMetamaskApp()">Use Metamask Browser (Phone)</button>
      <button type="button" class="highlighted-btn" (click)="onInstallMetamaskExtension()">Install Extension (Desktop)</button>
      <button type="button" class="highlighted-btn" (click)="onCancel()">Cancel</button>
    </div>
  </div>
