<div class="d-flex flex-column justify-content-center align-items-center">
    <h4 class="modal-title">Thank you for purchasing Janex!</h4>
    <div class="modal-body">
        <div class="modal-body-items">
            <h6 class="btc-warning-message">Please be aware that BTC transactions require a number of confirmations. To avoid delay, please have BTC in your wallet before creating the invoice. Once these confirmations are done, you will instantly receive your required JNX.</h6>
            <h6 class="enter-email-label" *ngIf="!disableEmailInput">Please enter your email below to proceed:</h6>
            <h6 class="enter-email-label" *ngIf="disableEmailInput">Your email address: </h6>
            <div class="email-field-container field">
                <input *ngIf="!disableEmailInput" (input)="validateEmail()" class="email-field-input" [(ngModel)]="email" email
                    placeholder="example@somemail.com">
                <div *ngIf="disableEmailInput" class="email-field-input"
                    placeholder="example@somemail.com">{{email}}</div>
            </div>

            <h6 *ngIf="isEmailValid === false" class="btc-warning-message"><span
                    style="color: red;">**</span><span>Email address invalid</span></h6>

            <div *ngIf="(isEmailValid !== false) && !loading" style="min-height: 25px;"></div>

            <div *ngIf="loading" class="d-flex align-items-center justify-content-center">
                <div class="spinner-border text-primary" role="status">
                </div>
                <div class="" style="width: 10px"></div>
                <span class="">Generating receipt..</span>
            </div>

            <button *ngIf="!paymentUrl" [disabled]="isEmailValid !== true && !disableEmailInput" type="button" class="highlighted-btn"
                (click)="onProceed()">I
                understand, proceed with btc payment</button>

            <a [href]="paymentUrl" *ngIf="paymentUrl" class="highlighted-btn">
                Go to Payment Page
            </a>

        </div>
    </div>
    <div class="d-flex justify-content-around align-items-center footer">
        <button type="button" class="highlighted-btn" (click)="cancel()">Cancel</button>
    </div>
</div>