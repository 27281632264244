import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { EventsComponent } from './pages/events/events.component';
import { AssetRewardComponent } from './pages/asset-reward/asset-reward.component';
import { ReportComponent } from './pages/asset-reward/components/report/report.component';
import { SingleReportComponent } from './pages/asset-reward/components/report/single-report/single-report.component';
import { CombinedReportComponent } from './pages/asset-reward/components/report/combined-report/combined-report.component';
import { AssetRevestComponent } from './pages/asset-revest/asset-revest.component';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  // {
  //   path: "purchase",
  //   component: PurchaseComponent,
  // },
  {
    path: "asset-reward",
    component: AssetRewardComponent,
  },
  {
    path: "asset-reward/report",
    component: ReportComponent,
  },
  {
    path: "asset-reward/report/single/:txnNumber",
    component: SingleReportComponent,
  },
  {
    path: "asset-reward/report/combined",
    component: CombinedReportComponent,
  },
  {
    path: "asset-revest",
    component: AssetRevestComponent,
  },
  {
    path: "events",
    component: EventsComponent,
  },
  {
    path: "**",
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
