<section class="roadmap-section">
    <div class="roadmap-content d-flex flex-column justify-content-center align-items-center">
        <h1 class="roadmap-title title">Roadmap</h1>
       
    </div>

    <div class="wrapper blue-waves-background-repeat">
        <div class="center-line">
            <a href="#" class="scroll-icon"><i class="fas fa-caret-up"></i></a>
        </div>
        <div class="row row-1">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q3 2021:</span>
                    <span></span>
                </div>
                <div class="checked">JANEX Ideation</div>
                <div class="checked">Team Foundation</div>

                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-2">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q4 2021:</span>
                    <span></span>
                </div>
                <div class="checked">Social Media</div>
                <div class="checked">Website Live</div>
                <div class="checked">Lite Paper</div>
                <div class="checked">Team Foundation</div>

                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-1">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q1 2022:</span>
                    <span></span>
                </div>
                <div class="checked">Smart Contract Development</div>
                <div class="checked">Testing Smart Contract (Testnet)</div>
                <div class="checked">Smart Contract Auditing</div>
                <div class="checked">Whitelist</div>
                <div class="checked">Private Sale</div>
                <div class="checked">Token Generation Event (TGE)</div>
                <div class="checked">PancakeSwap Listing</div>


                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-2">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q2 2022:</span>
                    <span></span>
                </div>
                <div class="checked">Partnership Program for Affiliate Marketing (DCT Network)</div>
                <div class="checked">Live Event - Meet the Founders & Team (Dubai)</div>
                <div class="checked">Private Sales Round 2</div>
                <div class="checked">Partnership Program for Real Estate</div>
                <div class="checked">Partnership Program for E-commerce</div>
                <div class="checked">Integrate JNX with DCT Network Web 2 Based</div>
                <div class="checked">Integration Testing</div>
                <div class="checked">JNX with DCT Web 2 Launching</div>


                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-1">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q3 2022:</span>
                    <span></span>
                </div>
                <div class="checked">JNX Payment System</div>
                <div class="checked">Vest & Reward Mechanism</div>
                <div class="checked">Testing Vest & Reward (Testnet)</div>
                <div class="unchecked">Improving JNX Website v2</div>
                <div class="unchecked">Whitepaper</div>
                <div class="unchecked">Strategic Partnerships</div>
                <div class="unchecked">DEX Registration</div>
                <div class="unchecked">CMC Registration</div>


                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-2">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q4 2022:</span>
                    <span></span>
                </div>
                <div class="unchecked">Adding More Liquidity</div>
                <div class="unchecked">Launching of Vest & Reward Mechanism</div>
                    <div class="unchecked">Launching of JNX Website v2</div>
                    <div class="unchecked">Staking Mechanism</div>
                    <div class="unchecked">Testing Staking (Testnet)</div>
                    <div class="unchecked">Integration of Real Estate</div>
                    <div class="unchecked">Integration of E-commerce</div>
                    

                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-1">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q1 2023:</span>
                    <span></span>
                </div>
                <div class="unchecked">Testing Real Estate Integration (Testnet)</div>
                <div class="unchecked">Launching of JNX and Real Estate Program (UK)</div>
                    

                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-2" style="margin-bottom: 50px;">
            <section>
                <i class="icon fas fa-home"></i>
                <div class="details">
                    <span class="roadmap-title">Q2 2023:</span>
                    <span></span>
                </div>
                <div class="unchecked">Testing E-Commerce Integration (Testnet)</div>
                <div class="unchecked">Launching of JNX and E-Commerce Program (UK)</div>

                <div class="bottom">

                    <i></i>
                </div>
            </section>
        </div>
        <div class="row row-2"></div>
    </div>
</section>