<!-- <div class="event-banner">
  <a class="moving-text-parent" href="https://dreamcometrue.ai/event.html">
    <h4 class="moving-text">Dreamers Victory Event 2023! Grab your tickets now and join us on 3 - 4 May 2023. See
      you
      in Istanbul, Türkiye!</h4>
  </a>
</div> -->

<!------------------->
<!-- INTRO SECTION -->
<!------------------->
<section class="intro-section">

  <div class="d-flex align-items-center justify-content-center">
    <h3 class="purchase-section-title">Purchase Janex</h3>
  </div>
  <div class="container-fluid">
    <div class="d-flex flex-column flex-md-row justify-content-around align-items-center">

      <section class="purchase-section">
        <div class="container-fluid">
          <div *ngIf="cannotFetchValues" class="cannot-fetch-values-container field" style="text-align: center">
            <div class="d-flex flex-column gap-3 justify-content-center align-items-center">
              <h3 class="title">Please try again</h3>
              <h5>Due to high load on the server or internet connection issues, the service you selected
                may be unavailable at the
                moment. Please refresh and try again in a minute.</h5>
              <h5>You may contact <span><a href="mailto: support@janex.finance"
                    target="_blank">support@janex.finance</a></span> if you continue to see this
                message after 10 minutes.</h5>

              <hr>
              <h5>Auto-refresh in {{autorefreshTimerValue}}</h5>

              <ngb-progressbar type="info" style="min-width: 150px;" [value]="20 - autorefreshTimerValue" [max]="20">
              </ngb-progressbar>
            </div>


          </div>

          <div *ngIf="!cannotFetchValues" class="purchase-section-items">
            <!-- <app-janex-wallet-picker *ngIf="!userJanexWalletAddress"
                            (walletAddressChanged)="onWalletAddressChanged($event)"></app-janex-wallet-picker> -->

            <!-- *ngIf="userJanexWalletAddress" -->
            <div class="purchase-section-items large-gap">
              <!-- <button (click)="onChangeWalletAddress()" class="switch-wallet-btn field">
                                <div class="switch-wallet-btn-change">Change</div>
                                <div class="switch-wallet-btn-content">
                                    <span class="switch-wallet-btn-label">Your Janex Wallet: </span>
                                    <span appMiddleEllipsis [fallbackMaxWidth]="100">{{userJanexWalletAddress}}</span>
                                </div>
                            </button> -->

              <div class="purchase-section-title-container">
                <h5 style="text-align: center; max-width: 600px;">Please select the currency, input the
                  amount you would like to pay/recieve, then press "Buy Now".</h5>
              </div>

              <div class="currency-field-container field">
                <div class="dropdown d-flex justify-content-between align-items-center">
                  <img [src]="currencyImage" class="crypto-icon">
                  <input [disabled]="fetchingUsdValues" [(ngModel)]="currentCurrencyValue"
                    (input)="onCurrencyValueChanged()" min="0" class="currency-input" type="number">

                  <button class="currency-button highlighted-btn" href="#" id="currencyDropdown" role="button"
                    data-bs-offset="0,-100" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="currency-button-items d-flex justify-content-between align-items-center">
                      <div>{{currentPaymentMethod}}</div>
                      <img src="/assets/images/icons/dropdown-arrow.png" class="dropdown-arrow">
                    </div>
                  </button>

                  <ul class="dropdown-menu" aria-labelledby="currencyDropdown">
                    <div *ngFor="let currencyName of paymentMethods; let i = index">
                      <li>
                        <a class="dropdown-item" (click)="changePaymentMethod(currencyName)">{{currencyName}}</a>
                      </li>
                      <li>
                        <hr *ngIf="i !== (paymentMethods.length-1)" class="dropdown-divider">
                      </li>
                    </div>
                  </ul>
                </div>
              </div>

              <div class="janex-amount-field-container d-flex align-items-center field">
                <div class="janex-amount-field-row d-flex align-items-center gap-3">
                  <img src="/assets/images/icons/janex-token.png" class="crypto-icon">
                  <input type="number" [disabled]="fetchingUsdValues" [(ngModel)]="currentJanexValue" min="0"
                    (input)="onJanexValueChanged()" class="janex-input">
                </div>
              </div>
            </div>

            <!-- *ngIf="userJanexWalletAddress" -->
            <div class="d-flex flex-column justify-content-center align-items-center gap-4">

              <div [ngSwitch]="janexValueErrorMessage" class="">
                <div *ngSwitchCase="undefined" class="spacer" style="min-height: 25px;"></div>
                <div *ngSwitchDefault class="">
                  <span style="color: red">** </span><span>{{janexValueErrorMessage}}</span>
                </div>
              </div>

              <button [disabled]="gettingTargetWalletAddress || janexValueErrorMessage || fetchingUsdValues"
                class="highlighted-btn buy-now-btn" (click)="onBuyPressed()">{{fetchingUsdValues ?
                'Fetching Values': 'Buy Now'}}</button>
              <div *ngIf="gettingTargetWalletAddress" class="d-flex justify-content-center align-items-center gap-1">
                <div class="spinner-border text-primary" role="status"></div>
                <h6>Fetching payment info</h6>
              </div>
            </div>

          </div>
        </div>

      </section>
      <div class="intro-shape-container image-animate">
        <img class="blockchain-polyhedron" src="/assets/images/background-images/blockchain-polyhedron.png">
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
        <div class="blockchain-polyhedron-particle"></div>
      </div>
    </div>
  </div>
</section>
