<div class="d-flex flex-column justify-content-center align-items-center">

  <h4 class="modal-title">Invoice Id: {{invoiceId}}</h4>
  <div class="modal-body">
    <h6 class="guide-text">Kindly use one of these methods to complete the purchase</h6>

    <div class="amount-to-be-paid-container">
      <div class="d-flex flex-column flex-md-row align-items-center justify-items-around gap-2 m-2">
        <h6>Exact amount to be paid: </h6>
        <button (click)="onCopyExactAmount()" class="field amount-to-be-paid-btn">
          <h6>{{cryptoCurrencyValue}} {{cryptoType!}}</h6>
          <div class="copy-amount-btn">Copy</div>
        </button>
      </div>
    </div>


    <div class="payment-methods-container">
      <div class="payment-methods-items">
        <div (click)="setSelectedMethod('browser')" class="payment-method-item selected"
          [ngClass]="{'selected': selectedMethod === 'browser'}">
          <a>
            <img class="payment-method-logo-image" src="/assets/images/icons/browser-icon.png" alt="browser">
          </a>
          <h6>Current Browser</h6>
        </div>
        <div (click)="setSelectedMethod('metamask')" class="payment-method-item"
          [ngClass]="{'selected': selectedMethod === 'metamask'}">
          <a>
            <img class="payment-method-logo-image" src="/assets/images/icons/mobile-app.png" alt="metamask">
          </a>
          <h6>Wallet App</h6>
        </div>
        <!-- <div (click)="setSelectedMethod('trustwallet')" class="payment-method-item" [ngClass]="{'selected': selectedMethod === 'trustwallet'}">
            <a>
              <img class="payment-method-logo-image" src="/assets/images/logo/trustwallet-logo.png" alt="trustwallet" >
            </a>
            <h6>Trustwallet App</h6>
          </div> -->
        <div (click)="copyAddress()" class="payment-method-item" [ngClass]="{'selected': selectedMethod === 'copy'}">
          <a>
            <img class="payment-method-logo-image" src="/assets/images/icons/copy.png" alt="copy">
          </a>
          <h6>Copy Address</h6>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="d-flex align-items-center justify-content-center m-4">
      <div class="spinner-border text-primary" role="status">
      </div>
      <div class="" style="width: 10px"></div>
      <span class="">{{loadingText}}</span>
    </div>

    <div class="payment-method-display-container">
      <div class="payment-method-display">
        <button (click)="onPayThroughBrowser()" *ngIf="selectedMethod === 'browser'" class="highlighted-btn">Press here
          to start</button>
        <div *ngIf="selectedMethod === 'metamask'" class="qrcode-container">
          <qrcode [width]="250" [qrdata]="metamaskDeeplink"></qrcode>
        </div>
        <button *ngIf="selectedMethod === 'copy'" class="highlighted-btn">Press here to copy</button>
      </div>
    </div>


  </div>
  <div class="d-flex justify-content-around align-items-center footer">
    <button type="button" class="highlighted-btn" (click)="cancel()">Cancel</button>
  </div>
</div>