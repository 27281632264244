import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  copyContractAddress() {
    navigator.clipboard.writeText("0xd3C117bD35025786714B56F44C93AD6DeB68B4D1");
    (document.getElementById('contract-copied') as HTMLInputElement).innerHTML = 'Copied...';
  }

}
