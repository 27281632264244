import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntroComponent } from './intro/intro.component';
import { HomeComponent } from './home.component';
import { TokenomicsComponent } from './tokenomics/tokenomics.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { WhyJanexComponent } from './why-janex/why-janex.component';
import { RoadmapComponent } from './roadmap/roadmap.component';
import { EconomyComponent } from './economy/economy.component';
import { AppRoutingModule } from 'src/app/app-routing.module';



@NgModule({
  declarations: [
    IntroComponent,
    HomeComponent,
    TokenomicsComponent,
    WhyJanexComponent,
    RoadmapComponent,
    EconomyComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    AppRoutingModule
  ]
})
export class HomeModule { }
