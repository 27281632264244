import { Component, HostListener, OnInit } from '@angular/core';
import { ApexChart, ApexNonAxisChartSeries } from 'ng-apexcharts';

@Component({
  selector: 'app-tokenomics',
  templateUrl: './tokenomics.component.html',
  styleUrls: ['./tokenomics.component.scss']
})
export class TokenomicsComponent implements OnInit {
  series: ApexNonAxisChartSeries = [5, 5, 50, 13, 2, 22, 3]

  labels = ["Token Allocation for Tax", "Marketing", "Public Sale", "Development", "Team", "Private Sale", "Advisors"]

  chart: ApexChart = {
    type: 'pie',
    toolbar: {
      show: false
    }

  }

  stroke = {
    show: false,
  }

  fill = {
    opacity: 0.9
  }

  legend = {
    position: this.position,
    itemMargin: {
      horizontal: 10,
    },
    fontSize: '16px',
    labels: {
      colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff',]
    }
  }

  dataLabels = {
    enabled: false,
  }

  screenWidth: number = 0;

  @HostListener('window:resize', ['$event'])
  
  onResize(event : any) {
    this.screenWidth = window.innerWidth;
  }

  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  private get showLegend() {return  this.screenWidth > 530}

  private get position() : 'bottom' | 'top' | 'right' | 'left' {return 'bottom'}
}
