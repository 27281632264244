import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Web3 from 'web3';

@Injectable({
  providedIn: 'root'
})
export class AppWeb3Service {
  private _walletAddress = new BehaviorSubject<string>('');

  public web3?: Web3;

  private _isConnected = new BehaviorSubject<boolean>(false);

  public get isConnectedObs() { return this._isConnected.asObservable() }

  public get isConnected() { return this._isConnected.value }

  public get currentWalletAddress() {
    return this._walletAddress.value;
  }

  public get walletAddressObs() {
    return this._walletAddress.asObservable()
  }

  constructor() { }

  public initialize() {
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
      window.ethereum.on('accountsChanged', (accounts: any) => {
        this.connectWallet();
      })
    }
  }

  public hasEthereumInstance() : boolean {
    if(window.ethereum) return true;
    else return false;
  }

  public async connectWallet(): Promise<string> {
    let rs = await window.ethereum.request({ method: "eth_requestAccounts" });
    let walletAddress = rs[0];
    if (walletAddress) {
      this._walletAddress.next(walletAddress);
      this._isConnected.next(true);
    } else {
      this._isConnected.next(false);
    }
    return walletAddress;
  }

  public async switchNetwork(id: string | number) {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: this.web3!.utils.toHex(id) }],
    });
  }

  public checkAndFixNumberOfDecimals(num: number, decimals = 18): number {
    return Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
  }

  public fromWei(value : string, uint? : any) {
    return Web3.utils.fromWei(value, uint)
  }

  public toWei(value : any, uint? :any) {
    return Web3.utils.toWei(value, uint)
  }
}
