import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { AppWeb3Service } from '../../app-web3.service';
import { busdAbi } from './busd-model';

@Injectable({
  providedIn: 'root'
})
export class BusdContractService {

  constructor(private web3Service : AppWeb3Service) { }

  loadBusdContract() {
    let web3 = new Web3(new Web3.providers.HttpProvider(environment.bscProviderUrl))
    return new web3.eth.Contract(busdAbi, environment.busdContractAddress);
  }

  async balanceOfWallet(walletAddress : string) : Promise<number> {
    const result = await this.loadBusdContract().methods.balanceOf(walletAddress).call()
    return result as number
  }
}
