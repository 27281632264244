import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppWeb3Service } from 'src/app/web3-services/app-web3.service';
import { environment } from 'src/environments/environment';
import { EnterAddressModalComponent } from '../../modals/enter-address-modal/enter-address-modal.component';

@Component({
  selector: 'app-janex-wallet-picker',
  templateUrl: './janex-wallet-picker.component.html',
  styleUrls: ['./janex-wallet-picker.component.scss']
})
export class JanexWalletPickerComponent implements OnInit {
  @Output("walletAddressChanged") onWalletAddressChanged = new EventEmitter<string>()

  isLastWalletAddressAvailable = false;

  constructor(private web3Service : AppWeb3Service, private modalService : NgbModal) { }

  ngOnInit(): void {
    this.checkIfPreviousWalletHasBeenUsed();
  }

  async onUseConnectedWallet() {
    if (!this.web3Service.isConnected) {
      this.updateWalletAddress(await this.web3Service.connectWallet())
    } else {
      this.updateWalletAddress(this.web3Service.currentWalletAddress)
    }
  }

  async onEnterJanexWalletAddressPressed() {
    let modalRef = this.modalService.open(EnterAddressModalComponent)
    let walletAddress = await modalRef.result;
    this.updateWalletAddress(walletAddress)
  }

  onUseLastWalletAddress() {
    this.updateWalletAddress(this.getLastWalletAddressFromLocalStorage()!)
  }

  private checkIfPreviousWalletHasBeenUsed() {
    this.isLastWalletAddressAvailable = this.getLastWalletAddressFromLocalStorage() != null;
  }

  private getLastWalletAddressFromLocalStorage(): string | null {
    return localStorage.getItem(environment.purchaseLastWalletAddressLocalStorageKey)
  }

  private saveWalletAddressToLocalStorage(address: string) {
    localStorage.setItem(environment.purchaseLastWalletAddressLocalStorageKey, address);
  }

  private updateWalletAddress(walletAddress: string | undefined) {
    if (walletAddress) {
      this.saveWalletAddressToLocalStorage(walletAddress)
      this.isLastWalletAddressAvailable = true;
      this.onWalletAddressChanged.emit(walletAddress)
    }
  }

}
