<section class="section">
    <div class="d-flex align-items-center justify-content-center">
        <h3 class="purchase-section-title">Combined Reports</h3>
    </div>

    <div class="container-fluid">
        <div class="d-flex flex-column flex-md-column justify-content-around align-items-baseline">

            <a href="/asset-reward/report" mat-raised-button color="primary" class="cta-btn">
                Back to Report
            </a>

            <div class="d-flex flex-column  table-wrapper">
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
                    <ng-container *ngFor="let column of displayedColumns; let i = index" [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef>{{ formatColumnName(column) }}</th>
                        <!-- <td mat-cell *matCellDef="let element">{{ element[column] }}</td> -->
                        <td mat-cell *matCellDef="let element">
                            {{ formatCellValue(column, element[column]) }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
        <div class="d-flex paginator-wrapper">

                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

            </div>
        </div>
    </div>
</section>